
export default {
  state: {
    deployEndpoint: process.env.VUE_APP_DEPLOY_ENDPOINT,
    expireTime: 0,
    showExpireTime: true
  },
  getters: {
    expireDateStr (state) {
      return (new Date(state.expireTime * 1000)).toLocaleString()
    },
    expireDays (state) {
      return Math.round((new Date(state.expireTime * 1000) - new Date()) / (3600000 * 24))
    },
    showExipreTime (state) {
      return state.showExpireTime
    }
  },
  mutations: {
    setExpireTime (state, payload) {
      state.expireTime = payload.seconds
    },
    setShowExpireTime (state, show) {
      state.showExpireTime = show
    }
  },
  actions: {
    updateDemoExpireTime ({ state, commit }) {
      window.axios.get(state.deployEndpoint + '/deploy/status')
        .then(response => {
          const b = response.data
          if (Object.keys(b).length > 0) {
            commit('setExpireTime', { seconds: b.expire_time })
          } else {
            commit('setExpireTime', { seconds: 0 })
          }
        }).catch(error => {
          window.console.log(error.message)
        })
    }
  }
}
