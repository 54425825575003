<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div class="form-signin">
      <div class="text-center mb-2">
        <router-link to="/auth/login">
          <img class="img-signin" src="@/assets/images/DevAlert_logo.png" alt="logotype" />
        </router-link>
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <form class="form-signin" v-on:submit.prevent="sendForgottenMail()" v-if="!isLoading && !showConfirmationBox && !successBox">
          <div class="form-label-group" v-if="!showConfirmationBox">
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="Email"
              required
              v-model="email"
            >
            <label for="inputPassword">Enter your email</label>
          </div>

          <button class="btn btn-lg btn-primary btn-block" type="submit">Send</button>

          <p class="mt-5 mb-3 text-center text-white">Copyright&nbsp;©&nbsp;2017–{{ new Date().getFullYear() }}&nbsp;Percepio&nbsp;AB. All&nbsp;Rights&nbsp;Reserved.</p>
      </form>

      <form class="form-signin" v-on:submit.prevent="confirmPasswordChange()" v-if="!isLoading && showConfirmationBox && !successBox">

          <div class="alert alert-info">
              New password must be at least 8 characters long. Contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.
          </div>

          <div class="form-label-group">
            <input
              type="password"
              id="password"
              class="form-control"
              placeholder="Password"
              required
              v-model="newPassword"
            >
            <label for="inputPassword">Enter new password</label>
          </div>

          <div class="form-label-group">
            <input
              type="text"
              id="text"
              class="form-control"
              placeholder="verificationCode"
              required
              v-model="verificationCode"
            >
            <label for="inputPassword">Verification code (check mail)</label>
          </div>

          <button class="btn btn-lg btn-primary btn-block" type="submit">Confirm</button>

          <p class="mt-5 mb-3 text-center text-white">Copyright&nbsp;©&nbsp;2017–{{ new Date().getFullYear() }}&nbsp;Percepio&nbsp;AB. All&nbsp;Rights&nbsp;Reserved.</p>
      </form>

      <div v-if="successBox">
        Your password has successfully been resettet, hold on while we redirect you to login page.
      </div>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'
import router from '../../router/index'

export default {
  data: () => ({
    isLoading: false,
    errorMessage: '',
    email: '',
    username: '',
    newPassword: '',
    verificationCode: '',
    showConfirmationBox: false,
    successBox: false
  }),
  methods: {
    sendForgottenMail: function () {
      this.errorMessage = ''
      const that = this

      this.username = this.email
      this.$store.commit('clearError')
      this.$store.commit('setUserPool')
      this.$store.commit('setCognitoDetails', {
        Username: this.username
      })

      this.$store.state.auth.cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
        },
        onFailure: function (error) {
          that.errorMessage = error.message
        },
        inputVerificationCode: function (data) {
          that.showConfirmationBox = true
        }
      })
    },
    confirmPasswordChange: function () {
      const that = this
      this.$store.commit('clearError')
      this.$store.commit('setUserPool')
      this.$store.commit('setCognitoDetails', {
        Username: this.username
      })

      this.$store.state.auth.cognitoUser.confirmPassword(this.verificationCode, this.newPassword, {
        onSuccess () {
          this.successBox = true
          setTimeout(() => {
            that.$store.dispatch('globalSignOut', {
              Username: that.username,
              Password: that.newPassword
            })
            router.push('/auth/login')
            this.successBox = false
          }, 1000)
        },
        onFailure (error) {
          that.errorMessage = error.message
        }
      })
    }
  },
  components: {
    spinner
  }
}
</script>
