<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Firmware Monitor (DFM) Library with SEGGER RTT
    </h2>
    <div class="help-section">
      <p>
        Besides regular cloud support, DevAlert also support getting the alerts from the device to the cloud using a PC connected with SEGGER J-Link,
        using SEGGER RTT protocol.
      </p>
      <p>
        With RTT, there is no way of having the protocol identify each device, and because of this it will be up to the user to define this when using
        the command line tool <b>devalertjlink</b>. With this, it is possible to get alerts sorted from different targets, but if this is not neccesary
        it is also possible to use the same name for each target (e.g. call them all "my_device"). The device identifier will be sent from the PC to the
        target using the RTT "down" buffer when the target is connected.
      </p>
    </div>

    <h4 class="with-actions">Configuration</h4>
    <div class="help-section">
      <p>
        In <i>dfmCloudPortConfig.h</i> setup the RTT parameters, including buffer sizes and which RTT index to use for up (target to PC) buffer
        and down (PC to target) buffer. The used index should not be used for any other data, to ensure the traces are not broken.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
