<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Percepio DevAlert Dispatcher
    </h2>
    <div class="help-section">
      <p>
        Alert payloads, like log files, traces or crash dumps, are not stored in the Percepio DevAlert cloud service,
        but in your own cloud. To be able to access these from the DevAlert Console, the DevAlert Dispatcher tool is used.
        It must be installed and configured on each machine that needs to open the custom URL scheme links starting with
        <samp>percepio://</samp>
        so if these links are not working for you, verify you have installed and configured the Percepio DevAlert Dispatcher.
      </p>
      <p>
        If you need to open traces that are attached to alerts, you will need to
        <router-link :to='{ name: "Tracealyzer"}'>install and configure Tracealyzer&#174; as well</router-link>.
      </p>
      <p>
        Download Percepio DevAlert Dispatcher using the links below and install, then start it once to configure
        the needed settings to connect to the Perceio DevAlert Service and also to your own backend.
        After that it will be able to handle clicking on links here in the DevAlert Console.
      </p>
      <p>
        <ul>
          <li><router-link :to='{ name: "DispatcherDetails"}'>Setting up the Dispatcher</router-link></li>
          <li><router-link :to='{ name: "DispatcherExamples"}'>Examples of File Mappings and Scripts</router-link></li>
        </ul>
      </p>
    </div>

    <h3 class="with-actions">
      Download Links
    </h3>
    <div class="help-section">
      <p>
        Version {{latestVersion}}
        <ul>
          <li>For <a :href="`https://download.tracealyzer.io/DevAlertDispatcher-${latestVersion}-win64.zip`">Windows (64-bit)</a></li>
          <li>For <a :href="`https://download.tracealyzer.io/DevAlertDispatcher-${latestVersion}-linux64.tgz`">Linux (64-bit)</a></li>
        </ul>
      </p>
    </div>
    <div class="help-section">
      <v-expansion-panels variant="default">
        <v-expansion-panel v-for="i in versions"
        :key="i">
          <v-expansion-panel-title>Version {{ i }}</v-expansion-panel-title>
          <v-expansion-panel-text>
            <ul>
              <li>For <a :href="`https://download.tracealyzer.io/DevAlertDispatcher-${i}-win64.zip`">Windows (64-bit)</a></li>
              <li>For <a :href="`https://download.tracealyzer.io/DevAlertDispatcher-${i}-linux64.tgz`">Linux (64-bit)</a></li>
            </ul>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { VExpansionPanels, VExpansionPanel, VExpansionPanelTitle, VExpansionPanelText } from 'vuetify/components'

export default {
  data: () => ({
    latestVersion: '1.1.0',
    versions: ['1.0.7', '1.0.6', '1.0.5', '1.0.4', '1.0.3', '1.0.2', '1.0.1']
  }),
  components: {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelTitle,
    VExpansionPanelText
  },
  methods: {
    setShowOldies: function () {
      this.showOldDownloads = true
    }
  }
}
</script>
