<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div v-if="!isLoading">
      <router-link class='btn btn-secondary' :to='{ name: "Devices"}'>Back</router-link>

      <h2 class="with-actions">
        Edit {{ originalDescription }}
      </h2>

      <spinner v-if="isLoading"></spinner>

      <form v-on:submit.prevent="submitForm">
        <div class="float-right status-bar">
          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-success" :disabled="!collection.retired" v-on:click="updateStatus('published')">
              Publish
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-info" :disabled="collection.retired" v-on:click="updateStatus('retired')">
              Retire
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button class="btn btn-secondary"  type="submit" :disabled="collection.retired">
              Save Updates
            </button>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Identifier</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.identifier" pattern="[A-Za-z0-9-_:]+" title="Allowed chars: 'A-Z','a-z','0-9' and '-_:'" :disabled="collection.retired">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Description</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description" :disabled="collection.retired">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Product</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.productIdentifier" :disabled="collection.retired">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Created</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.created" disabled>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Updated</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.updated" disabled>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Retired</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.retired" disabled>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      key: null,
      identifier: null,
      description: null,
      lastIssueKey: null,
      productIdentifier: null,
      lastTraceKey: null,
      severity: null,
      created: null,
      updated: null,
      retired: null,
      state: false
    },
    originalDescription: '',
    isLoading: true
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    this.getEditData()
  },
  methods: {
    getEditData: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/devices/' + this.$route.params.id)
        .then(response => {
          if (!response.data.deviceDescriptions.length) {
            this.$router.push('/devices')
          }

          this.collection = response.data.deviceDescriptions[0]
          this.originalDescription = this.collection.description
          this.isLoading = false
        })
    },
    submitForm: function () {
      this.submitData({
        identifier: this.collection.identifier,
        description: this.collection.description,
        productIdentifier: this.collection.productIdentifier
      })
    },
    updateStatus: function (field) {
      this.collection.state = field

      this.submitData({
        alertType: this.$route.params.id,
        state: this.collection.state
      })
    },
    submitData: function (collection) {
      window.axios({
        method: 'PATCH',
        url: this.$store.state.auth.serviceEndpoint + '/devices/' + this.$route.params.id,
        data: collection
      }).then(response => {
        this.collection = response.data.deviceDescriptions[0]
        this.originalDescription = this.collection.description
      })
    }
  },
  components: {
    spinner
  }
}
</script>
