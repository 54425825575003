<template>
    <!-- Modal -->
    <div class="modal fade" id="viewDetails" tabindex="-1" role="dialog" aria-labelledby="viewDetails" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="labelDetails">Alert Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <table class="table table-sm" v-if="hasPayloads(payloadList)">
              <thead>
                <tr>
                  <th>File</th>
                  <th>Size</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payload, index) in payloadList" :key="index">
                  <td>
                    <a :href="'percepio://devalert/issue/' + alertKey + '/' + payload.key" @click="showLoader(payload)">
                      {{ !payload.isLoading ? payload.filename : '' }}
                      <div v-if="payload.isLoading" class="spinner"></div>
                    </a>
                  </td>
                  <td>{{ formatSize(payload.size) }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Alert Key</td>
                  <td>{{ alertKey }}</td>
                </tr>
                <tr>
                  <td>Revision</td>
                  <td>{{ firmwareRevision }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="modal-header">
            <h5 class="modal-title" id="labelDescription">Description</h5>
          </div>
          <div class="modal-body">
            <p>{{ alertDescription }}</p>
          </div>

          <div class="modal-header">
            <h5 class="modal-title" id="labelSymptoms">Symptoms</h5>
          </div>
          <div class="modal-body">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(symptomsListRow, index) in symptomsList" :key="index">
                  <td>{{ symptomsListRow.key }}</td>
                  <td>{{ symptomsListRow.description }}</td>
                  <td>{{ symptomsListRow.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { showLoader } from '../Utils/Global.js'

export default {
  props: {
    alertKey: String,
    symptomsList: Object,
    payloadList: Object,
    alertDescription: String,
    firmwareRevision: String
  },
  data: () => ({
    showLoader: showLoader
  }),
  methods: {
    hasPayloads: function (payloads) {
      return payloads.length > 0
    },
    hasTrace: function (payloads) {
      return (payloads.find(p => p.key === 'da_trace' || p.key === 'trace' || p.filename.startsWith('dfm_trace.')))
    },
    formatSize: function (size) {
      if (size < 1100) {
        return size + ' B'
      }

      if (size < 1060000) {
        return Number(size / 1024).toFixed(1) + ' KB'
      }

      return Number(size / (1024 * 1024)).toFixed(1) + ' MB'
    }
  }
}
</script>
