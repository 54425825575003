<template>
  <div>
    <h2 class="list-page width-actions">
      Feedback
    </h2>

    <div v-if="isWorking">
      <spinner/>
    </div>

    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div v-if="!isWorking && !hasErrors && !isSubmitted">
      <p>
        Send feedback to Percepio
      </p>

      <form class="form-signin" @submit.prevent="submitForm">
        <div class="form-group">
            <label for="inputCompany">Email <i>(optional)</i></label>

            <input
                type="text"
                id="inputEmail"
                class="form-control"
                placeholder=""
                v-model="form.email"
            />
        </div>

        <div class="form-group">
            <label for="inputCountry">How are you feeling about DevAlert</label>
            <small>
              <div class="alert alert-warning" v-if="v$.form.feels.required.$invalid" style="">{{ v$.form.feels.required.$message }}</div>
            </small>

            <select
              id="inputFeels"
              class="form-control"
              required
              v-model="form.feels"
            >
                <option value="VerySatisfied">Very satisfied</option>
                <option value="SomewhatSatisfied">Somewhat satisfied</option>
                <option value="SomewhatDissatisfied">Somewhat dissatisfied</option>
                <option value="VeryDissatisfied">Very dissatisfied</option>
            </select>
        </div>

        <div class="form-group">
            <label for="inputCompany">Message to Percepio</label>
            <small>
              <div class="alert alert-warning" v-if="v$.form.message.required.$invalid">{{ v$.form.message.required.$message }}</div>
            </small>

            <textarea
                id="inputMessage"
                class="form-control"
                placeholder="Enter your feedback message to Percepio"
                required
                autofocus
                v-model="form.message"
            ></textarea>
        </div>

        <button class="btn btn-lg btn-primary" type="submit" :disabled="v$.form.$invalid">Submit</button>
      </form>
    </div>

    <div v-if="isSubmitted">
      <p>
        Thanks for the feedback!
      </p>
      <p>
        Back to the <router-link :to='{ name: "Dashboard"}'>Dashboard</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      isWorking: false,
      isSubmitted: false,
      hasErrors: false,
      errorMessage: '',
      form: {
        email: '',
        feels: 'VerySatisfied',
        message: ''
      }
    }
  },
  validations () {
    return {
      form: {
        feels: { required, $autoDirty: true },
        message: { required, $autoDirty: true, $lazy: true }
      }
    }
  },
  mounted () {
    this.form.email = this.$store.state.auth.username
  },
  methods: {
    submitForm: function () {
      this.v$.form.feels.$touch()
      this.v$.form.message.$touch()

      if (this.v$.form.$invalid) {
        return
      }

      this.hasErrors = false
      this.isWorking = true

      window.axios.post(this.$store.state.auth.serviceEndpoint + '/feedback', this.form).then(response => {
        this.isSubmitted = true
        this.isWorking = false
      }).catch(error => {
        window.console.error(error.message)
        this.hasErrors = true
        this.isWorking = false
        this.errorMessage = 'Failed to send feedback'
        if (error.response) {
          this.errorMessage += ': ' + error.response.data.title
        }
      })
    }
  },
  components: {
    spinner
  }
}
</script>
