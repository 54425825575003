<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Alert Key Uniqueness
    </h2>
    <div class="help-section">
      <p>
        To ensure each Alert get their own unique key, the following format is used:<br/>
        <samp>DevAlert/[DEVICE_ID]/[SESSION_ID]/[ALERT_ID]</samp>
      </p>
      <p>
        The <samp>DEVICE_ID</samp> must be unique for each device.
        This should not be a problem for any connected device, since they will already have that requirement for the connection.
        Any string can be used, e.g. using a serial number generated when bootstrapping the device, or a manually entered value, as long as it is unique for the device fleet.<br/>
        <b>Note!</b> If using more than one product, each device must still be unique.
        If there are any risk of two devices from different products getting the same identifier, include the product in the <samp>DEVICE_ID</samp> string.<br/>
        Having a unique <samp>DEVICE_ID</samp> will ensure that the <samp>SESSION_ID</samp> only have to be unique per device, and not <i>globally</i> unique.
      </p>
      <p>
        The <samp>SESSION_ID</samp> must be unique for each device startup.
        It must be re-generated each time the device restart.
        A good example is to use a real timestamp, like Unix epoch time, where the resolution must be good enough to be unique for each startup.
        Most devices will take at least a few seconds to "get ready" when connecting to the cloud, so second resolution is good enough in these cases.
        For devices that often will boot up in time shorter than a second, millisecond resolution is recommended.<br/>
        For devices that is not able to retrieve a real world timestamp, a 64-bit counter stored in non-volatile memory (flash) is an option.
      </p>
      <p>
        The <samp>ALERT_ID</samp> is an internal counter used by DFM, which is increased each time an alerts is sent (or stored).
        It only exists in volatile memory, and is reset to 0 on each device restart.
      </p>
    </div>
    <div class="help-section">
      <ul>
        <li>If the Alert Key is not unique, older alerts will be overwritten, breaking the history.</li>
        <li>If the same <samp>DEVICE_ID</samp> is used for more than one device, different devices can overwrite each others alerts.</li>
        <li>If the same <samp>SESSION_ID</samp> is used within one device, only the alerts from that device will be overwritten.</li>
      </ul>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>
