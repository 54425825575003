<template>
  <v-app-bar color="#0f233b" :elevation="4" app>
<!--
    <template v-slot:prepend>
      <v-app-bar-nav-icon class="app-bar-icon"/>
    </template>
-->

    <v-app-bar-title class="app-bar-title m-3 text-nowrap">
      <img src="@/assets/images/DevAlert_Logo-White-150x33.png" alt="logotype"/>
    </v-app-bar-title>

    <v-card class="m-3">
      <v-btn v-if="!mobile" variant="text" to="/feedback">Feedback</v-btn>
      <v-menu v-if="hasServiceUrl" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" v-bind="props">
            Manage
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isAdmin"><v-btn variant="text" to="/admin" class="justify-start" block>User Management</v-btn></v-list-item>
          <v-list-item><v-btn variant="text" to="/notifications" class="justify-start" block>Notifications</v-btn></v-list-item>
          <v-list-item><v-btn variant="text" to="/usage" class="justify-start" block>Alert Usage</v-btn></v-list-item>
          <v-list-item v-if="isAdmin"><v-btn variant="text" to="/certificate" class="justify-start" block>Certificate</v-btn></v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!mobile" variant="text" @click="logoutUser()" link>Sign out</v-btn>
      <v-menu v-else>
        <template v-slot:activator="{ props }">
          <v-btn variant="text" icon="mdi-account" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text" to="/feedback">Feedback</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text" @click="logoutUser()" link>Sign out</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
  </v-app-bar>
</template>

<script>
import { VAppBar, VAppBarTitle, VCard, VBtn, VMenu, VList, VListItem } from 'vuetify/components'

export default {
  data: () => ({
    isAdmin: false
  }),
  components: {
    VAppBar,
    VAppBarTitle,
    VCard,
    VBtn,
    VMenu,
    VList,
    VListItem
  },
  mounted () {
    this.isAdmin = (this.$store.state.auth.role === 'Owner')
  },
  computed: {
    hasServiceUrl: function () {
      return (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    },
    mobile: function () {
      return this.$vuetify.display.mobile
    }
  },
  methods: {
    logoutUser: function () {
      this.$store.state.auth.cognitoUser.globalSignOut({ AccessToken: this.$store.state.auth.tokens.accessToken }, function (err, data) {
        if (err) console.log(err, err.stack)
      })
      this.$store.commit('signOut')
      this.$store.commit('setShowExpireTime', true)
      this.$router.push('/auth/login')
    }
  }
}
</script>
