<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Device setup using other RTOS
    </h2>
    <div class="help-section">
      <p>
        Percepio provides the option of doing your own Device mapping, using our Integration Package.
        <a href="mailto:support@percepio.com">Contact Percepio</a> if you want to know more.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
