<template>
  <div>
    <router-link class='btn btn-secondary' :to='{ name: "Devices"}'>Back</router-link>

    <h2 class="with-actions">
      New Device Description
    </h2>

    <form v-on:submit.prevent="submitFields">
      <div class="form-group">
          <label for="exampleFormControlInput1">Identifier</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.identifier" pattern="[A-Za-z0-9-_:]+"  title="Allowed chars: 'A-Z','a-z','0-9' and '-_:'">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Description</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Product</label>
          <select class="form-control" v-model.number="collection.productKey">
            <option v-for="(product, index) in products" :key="index" v-bind:value="product.key">{{ product.identifier }}</option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-lg btn-block btn-primary" type="submit">Submit</button>
        </div>
    </form>
    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      identifier: '',
      description: '',
      productKey: null,
      lastIssueKey: null,
      lastTraceKey: null
    },
    products: {},
    isLoading: false,
    hasErrors: false,
    errorMessage: ''
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    this.getProducts()
  },
  methods: {
    getProducts: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/products')
        .then(response => {
          this.products = response.data.productDescriptions
        }).catch(error => {
          console.log(error.message)
          this.errorMessage = 'Failed to get products: ' + error.message
          this.hasErrors = true
        })
    },
    submitFields: function () {
      this.isLoading = true
      this.hasErrors = false
      window.axios({
        method: 'POST',
        url: this.$store.state.auth.serviceEndpoint + '/devices',
        data: {
          identifier: this.collection.identifier,
          description: this.collection.description,
          productKey: this.collection.productKey
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$router.push('/devices')
        }
        this.collection = response.data.deviceDescriptions[0]
        this.isLoading = false
      }).catch(error => {
        console.log(error.message)
        this.isLoading = false
        this.hasErrors = true
        this.errorMessage = 'Failed to create device: ' + error.message
      })
    }
  },
  components: {
    spinner
  }
}
</script>
