<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Failed malloc calls in FreeRTOS
    </h2>
    <div class="help-section">
      <p>
        Targets can detect failed memory allocation (malloc) calls, which have their own fault handler.<br/>
        <!-- The code handler function is included in the <router-link :to='{ name: "DfmFiles"}'>DFM Recorder Files</router-link> (in <i>percepio_dfm.c</i>)
        but is disabled by default, and must be enabled in <i>percepio_dfmConfig.h</i>. -->
      </p>
    </div>
    <h4>
      Registering fault handler
    </h4>
    <div class="help-section">
      <p>
        To register fault handler to detect malloc failure, follow these steps:
      </p>
      <pre><code>
  /* In FreeRTOSConfig.h, enable the malloc failed hook; change value or add this define if not found */
  #define configUSE_MALLOC_FAILED_HOOK (1)

  /*
   * To prevent compilation warnings, add a function definition matching the predefined name (from heap_N.c).
   * Make sure this definition is included before FreeRTOSConfig.h, otherwise the compiler warning will still show.
   */
  void vApplicationMallocFailedHook();
      </code></pre>
    </div>
    <h4>
      Function handling the alert
    </h4>
    <div class="help-section">
      <p>
        This code is included in the <router-link :to='{ name: "DfmFiles"}'>DFM Download</router-link>
      </p>
      <pre><code>
  /* Error handler - Failed to allocate requested memory */
  void vApplicationMallocFailedHook()
  {
      DfmAlertHandle_t xAlertHandle;
      /* Create the alert, with details, an alert handle is returned */
      if (xDfmAlertBegin(DFM_ALERT_MALLOC_FAILED, "Malloc failed", &xAlertHandle) == DFM_SUCCESS)
      {
          /* Add alert symptoms */
          xDfmAlertAddSymptom(xAlertHandle, DFM_SYMPTOM_CURRENT_TASK, (uint32_t) pcTaskGetName(NULL));
          xDfmAlertAddSymptom(xAlertHandle, DFM_SYMPTOM_STACKPTR, (uint32_t) __get_PSP());

          /* Disable tracing */
          xTraceDisable();

          /* Add trace payload */
          prvAddTracePayload(xAlertHandle);

          /* Send the alert */
          xDfmAlertEnd(xAlertHandle);

          /* Enable tracing */
          xTraceEnable();
      }

      /* Any additional error handling follows, restart if needed? */
  }

  static void prvAddTracePayload(DfmAlertHandle_t alertHandle)
  {
      char* szName;
      void* pvBuffer = (void*)0;
      uint32_t ulBufferSize = 0;
      if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_STREAMING)
      {
          szName = "dfm_trace.psfs";
      }
      else if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_SNAPSHOT)
      {
          szName = "dfm_trace.bin";
      }

      xTraceGetEventBuffer(&pvBuffer, &ulBufferSize);
      xDfmAlertAddPayload(alertHandle, pvBuffer, ulBufferSize, szName);
  }
        </code></pre>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
        -
        <router-link :to='{ name: "DfmExamples"}'>Back to Examples</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
