<template>
  <div class="col-md-7" style="margin-left:0">
    <h2>Welcome to DevAlert</h2>

    <br>
    <p>
      To get started you need to activate the service. This should only take a few seconds.
    </p>
    <div class="setup-container">
      <button type="button" class="btn btn-primary" v-on:click="deploy()" :disabled="isWorking">
        Activate Service
      </button>
    </div>
    <br>
    <div v-if="isWorking && !isCreating">
      <p>
        Activating service, this will take a few seconds. Please wait.
      </p>
    </div>
    <div v-if="isCreating" class="alert alert-info" role="alert">
        No service available for activation, creating service. This will take a few minutes. Please wait.
    </div>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
    <spinner v-if="isWorking"></spinner>

  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'
import { Poller } from '../Utils/Global.js'

export default {
  data: () => ({
    isWorking: false,
    isCreating: false,
    hasErrors: false,
    errorMessage: '',
    deployEndpoint: '',
    poller: null,
    intervalMs: 20000
  }),
  mounted () {
    this.poller = new Poller(this.intervalMs)
    this.deployEndpoint = this.$store.state.demo.deployEndpoint
    this.deployStatus()
  },
  methods: {
    deploy: function () {
      this.isWorking = true
      this.hasErrors = false

      window.axios.get(this.deployEndpoint + '/deploy')
        .then(response => {
          const b = response.data
          if (b.stack_status === 'CREATE_IN_PROGRESS') {
            this.pollStatus()
            this.isCreating = true
            return
          }
          if (b.stack_status === 'CREATE_COMPLETE') {
            this.isWorking = false
            this.isCreating = false
            this.$store.commit('setExpireTime', { seconds: b.expire_time })
            this.$router.push({ name: 'Dashboard' })
            return
          }
          // There is an error if not create in progress or complete
          this.isWorking = false
          this.hasErrors = true
          this.errorMessage = 'Failed to activate service: ' + b.message
          throw new Error(b.message)
        }).catch(error => {
          window.console.log(error.message)
        })
    },
    pollStatus: function () {
      this.poller.get(this.deployEndpoint + '/deploy', (response) => {
        // Check status
        const b = response.data
        if (b.stack_status === 'CREATE_COMPLETE') {
          // Check service_url and update user
          window.console.log(b.service_url)
          return true
        }
        if (b.stack_status === 'DELETE_COMPLETE') {
          if (parseInt(b.expire_time) * 1000 < Date.now()) {
            const d = new Date(parseInt(b.expire_time) * 1000)
            throw new Error('The Sandbox Demo environment expired at ' + d.toString())
          }
          throw new Error('The user already have a stack that was deleted')
        }
        if (b.stack_status === 'ROLLBACK_COMPLETE') {
          throw new Error('Failed to deploy stack')
        }
        // Continue poll
        return false
      })
        .then(response => {
          window.console.log(response.data)
          this.isWorking = false
          const b = response.data
          this.$store.commit('setExpireTime', { seconds: b.expire_time })
          this.$router.push({ name: 'Dashboard' })
        }).catch(error => {
          window.console.error(error.message)
          this.isWorking = false
          this.hasErrors = true
          this.errorMessage = 'Failed to create environment: ' + error.message
        })
    },
    deployStatus: function () {
      window.axios.get(this.deployEndpoint + '/deploy/status')
        .then(response => {
          const b = response.data
          if (b.stack_status === 'CREATE_IN_PROGRESS') {
            this.isWorking = true
            this.isCreating = true
            this.hasErrors = false
            this.pollStatus()
          }
          if (b.stack_status === 'CREATE_COMPLETE') {
            this.hasErrors = false
            this.isCreating = false
            this.$store.commit('setExpireTime', { seconds: b.expire_time })
            this.$router.push({ name: 'Dashboard' })
          }
          if (b.stack_status === 'DELETE_COMPLETE') {
            this.hasErrors = true
            this.isWorking = false
            // this.errorMessage = b.message
            if (parseInt(b.expire_time) * 1000 < Date.now()) {
              const d = new Date(parseInt(b.expire_time) * 1000)
              this.errorMessage = 'The Sandbox Demo environment expired at ' + d.toString()
              return
            }
            this.errorMessage = 'The user already have a stack that was deleted'
          }
          if (b.stack_status.includes('ROLLBACK_')) {
            throw new Error('Failed to deploy stack')
          }
        }).catch(error => {
          window.console.log(error.message)
          this.isWorking = false
          this.hasErrors = true
          this.errorMessage = 'Failed to read service status: ' + error.message
        })
    }
  },
  components: {
    spinner
  },
  beforeRouteLeave (to, from, next) {
    this.poller.stop()
    next()
  }
}
</script>
