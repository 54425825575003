<template>
  <div>
    <h2>Alert Usage</h2>

    <div>
        <chart ref="usageChart" />
    </div>

    <div class="alert alert-danger" v-if="!isLoading && !hasErrors && showUsageHigh" style="margin: 1rem;">
      <b>Alert Usage is very high</b>
    </div>

    <div class="alert alert-warning" v-if="!isLoading && !hasErrors && showUsageMedium" style="margin: 1rem;">
      <b>Alert Usage is high</b>
    </div>

    <div v-if="!isLoading && !hasErrors" style="margin: 1rem; margin-bottom: 2rem;">
      {{ usageDescription }}
    </div>

    <div v-if="!isLoading" style="margin: 1rem;">
      <p>If your devices are reporting more alerts than expected, check for redundant alerts.</p>
      <p><a href="mailto:support@percepio.com">Contact Percepio</a> if you need to increase the limit.</p>
    </div>
  </div>
</template>

<script>
import chart from './Chart.vue'

export default {
  data: () => ({
    isLoading: true,
    hasErrors: false,
    showUsageHigh: false,
    showUsageMedium: false,
    usageValue: 5,
    quotaValue: 100,
    progressValue: 5,
    usageDescription: 'Current Alert Usage, click for details'
  }),
  mounted () {
    if (this.loadPage) {
      this.getUsage()
    }
  },
  methods: {
    getUsage: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/usage')
        .then(response => {
          if (response.data.code === 200) {
            this.quotaValue = response.data.currentUsage.quota
            this.usageValue = response.data.currentUsage.used
            this.progressValue = 100 * this.usageValue / this.quotaValue

            if (this.progressValue > 85) {
              this.showUsageHigh = true
              this.showUsageMedium = false
            } else if (this.progressValue > 70) {
              this.showUsageHigh = false
              this.showUsageMedium = true
            }

            const timeBase = window._.camelCase(response.data.currentUsage.timeBase)
            this.usageDescription = 'Currently used ' + this.usageValue + ' of ' + this.quotaValue + ' alerts (per ' + timeBase + ')'

            this.isLoading = false

            this.$refs.usageChart.setData(response.data)
          } else {
            window.console.log(response.data.errors[0])

            this.hasErrors = true
            this.isLoading = false

            this.$refs.usageChart.setError('Failed to get usage: ' + response.data.errors[0])
          }
        }).catch(error => {
          window.console.error(error.message)

          this.hasErrors = true
          this.isLoading = false

          this.$refs.usageChart.setError('Failed to get usage: ' + error.message)
        })
    }
  },
  computed: {
    loadPage: function () {
      return this.isAuthenticated && (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  components: {
    chart
  }
}
</script>
