<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Examples of DFM integrations
    </h2>
    <div class="help-section">
      <p>
        Make sure to view our examples below.
      </p>
      <ul>
        <li>
          <router-link :to='{ name: "DfmCrashCatcher"}'>Crash Catcher Integration</router-link>
        </li>
        <li>
          <router-link :to='{ name: "DfmExamplesApplictionState"}'>Invalid state in application code</router-link>
        </li>
        <li>
          <router-link :to='{ name: "DfmExamplesFreeRTOSMallocFail"}'>Failed malloc calls in FreeRTOS</router-link>
        </li>
        <li>
          <router-link :to='{ name: "DfmExamplesCortexMHardFault"}'>Cortex-M Hard Fault handler</router-link>
        </li>
      </ul>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
