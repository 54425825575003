<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Using DFM with AWS IoT Core and MQTT
    </h2>
    <div class="help-section">
      <p>
        The recommended upload channel for devices using AWS is AWS IoT Core, using the AWS-provided MQTT library (coreMQTT).
        DFM provides a “cloud port” intended for the coreMQTT library defining how DFM should upload the data to your AWS account.
        The cloud port code is found under /cloudports and does not include the coreMQTT library, since this is assumed to be present already.
      </p>
      <p>
        Note that each cloud port has their own version of dfmCloudPort.h, so make sure to only include the AWS cloud port in the compiler include path.
        The cloud port folder may also contain .c files that need to be included in the build.
      </p>
    </div>

    <h4 class="with-actions">Configuration</h4>
    <div class="help-section">
      <p>
        Relevant settings for the cloud port are found in <i>dfmConfig.h</i> and <i>dfmCloudPortConfig.h</i>.
        You may need to tweak these settings to get the best possible result when sending alerts to the AWS cloud.
      </p>
      <p>
        Note that DFM alerts may be fairly large (kilobytes) compared to typical MQTT messages and some network stacks may have limitations in the payload size.
        DFM therefore provides an option for sending the alerts in several “chunks” to reduce the MQTT message size, <samp>DFM_CFG_MAX_PAYLOAD_CHUNK_SIZE</samp> in <i>dfmConfig.h</i>.
        By using a smaller chunk size, you also reduce the RAM usage of the DFM library since it uses an internal buffer based on this setting.
        The drawback of a smaller chunk size is that large alerts may require many MQTT messages to upload.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
