<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setup Device Backend
    </h2>
    <div class="help-section">
      <p>
        Use link below to see how to set up your Device Backend.
      </p>
      <p>
        <ul>
          <li v-if="showAws"><router-link :to='{ name: "StartupCustomerAws"}'>Amazon Web Services (AWS)</router-link></li>
          <li v-if="showAzure"><router-link :to='{ name: "StartupCustomerAzure"}'>Microsoft Azure</router-link></li>
          <li v-if="showLocal"><router-link :to='{ name: "StartupCustomerLocalStorage"}'>Local Storage</router-link></li>
        </ul>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false,
    showAws: true,
    showAzure: true,
    showLocal: true
  }),
  computed: {
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    }
  },
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    this.showAws = this.$store.state.auth.filterTags.includes('AWS')
    this.showAzure = this.$store.state.auth.filterTags.includes('Azure') && this.hasDeveloperFeature
    this.showLocal = this.$store.state.auth.filterTags.includes('Local')
  }
}
</script>
