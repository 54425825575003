<template>
  <div style="min-height: 300px;">
    <canvas id="alert-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import { Poller } from '../Utils/Global.js'

export default {
  computed: {
    loadPage: function () {
      return this.isAuthenticated && (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (this.loadPage) {
      this.poller = new Poller(this.intervalMs)
      this.pollStatisticData()
    }
  },
  watch: {
    isAuthenticated: function () {
      if (!this.$store.state.auth.authenticated) {
        this.poller.stop()
      }
    }
  },
  data: () => ({
    chartData: {
      type: 'bar',
      data: {
        labels: [],
        datasets: {}
      },
      options: {
        responsive: true,
        lineTension: 0,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (Number.isSafeInteger(value)) {
                  return value
                }

                return ''
              }
            }
          }]
        }
      }
    },
    poller: null,
    intervalMs: 5000,
    chart: null
  }),
  methods: {
    leave: function () {
      if (this.poller) {
        this.poller.stop()
      }
    },
    pollStatisticData: function () {
      this.poller.get(this.$store.state.auth.serviceEndpoint + '/statistics', (response) => {
        if (this.chart == null) {
          this.chart = this.createChart()
        }
        this.chart.data.datasets = response.data.data.dataSets
        this.chart.data.labels = response.data.data.labels.map(this.$global.toLocaleDate)
        this.chart.update()
        return false
      }).catch(error => {
        window.console.error(error.message)

        if (error.response.status === 401 || error.response.status === 403) {
          // this means that user session is inexistent or has timed out.
          // reload the entire Dashboard
          this.$router.go()
        }
      })
    },
    createChart () {
      const ctx = document.getElementById('alert-chart')
      return new Chart(ctx, {
        type: this.chartData.type,
        data: this.chartData.data,
        options: this.chartData.options
      })
    }
  }
}
</script>
