<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Getting Started Guide for Azure RTOS
    </h2>
    <div class="help-section">
      <p>
        For Azure RTOS, trace recording is alread built in.
      </p>
      <p>
        Enable tracing doing this:
        <ol>
          <li>Step 1</li>
          <li>Step 2</li>
          <li>Step 3</li>
        </ol>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
