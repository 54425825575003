<template>
  <div>
    <h2 class="list-page width-actions" >
      Alerts
    </h2>

    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div class="table-responsive" v-if="!isLoading && !hasErrors">
      <div>
        <form>
        <table style="width:100%; border: 0px">
          <tr>
            <td style="width:*">
              <input type="text" class="form-control" id="textFilter42" placeholder="Filter for text" v-model="searchFields.textFilter">
            </td>
            <td style="width:110px; text-align: center!important;">
              <button type="submit" class="btn btn-info" v-on:click.prevent="performFilter">Apply</button>
            </td>
            <td style="width:110px; text-align: center!important;">
              <button type="submit" class="btn btn-secondary" v-on:click.prevent="clearFilter">Clear</button>
            </td>
          </tr>
        </table>
        </form>
      </div>

      <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th class="sort-by" @click="sortBy('alertTypeDescription', $event)">
                Description
                <span class="oi oi-caret-bottom"></span>
              </th>
              <th class="sort-by" @click="sortBy('device', $event)">
                Device
                <span class="oi oi-caret-bottom"></span>
              </th>
              <th class="sort-by" @click="sortBy('created', $event)">
                Created
                <span class="oi oi-caret-bottom"></span>
              </th>
              <th>Details</th>
              <th>Trace</th>
            </tr>
          </thead>
          <tbody>

            <tr
              v-for="(dataRow, index) in collection"
              :key="index"
            >
              <td>{{ dataRow.alertTypeDescription}}</td>
              <td>{{ dataRow.device}}</td>
              <td>{{ $global.toLocalTime(dataRow.timestamp) }}</td>
              <td>
                <button class="btn btn-secondary" data-toggle="modal" data-target="#viewDetails" :data-row="index">
                  Details
                </button>
              </td>
              <td>
                <a class="btn btn-primary" v-bind:class='{ "disabled": disableOpenButton(dataRow) }' title="Open Trace in Tracealyzer" v-bind:href="getTraceLink(dataRow)">
                  Open
                </a>
              </td>
            </tr>
          </tbody>
      </table>
      <pagination
        :total="paginate.total"
        :page-size="paginate.pageSize"
        :callback="getTableData"
        :currentPage="paginate.currentPage"
        nav-class="padding-10"
        ul-class="bg-color-red"
        li-class="txt-color-blue">
      </pagination>
    </div>

    <!-- Modal -->
    <alert-details id="viewDetails" :alert-key="stripFilename(selectedAlertKey)" :symptoms-list="selectedSymptomsList" :payload-list="selectedPayloadList" :alert-description="selectedAlertDescription" :firmware-revision="selectedFirmwareRevision"/>
  </div>
</template>

<script>
import AlertDetails from '../Common/AlertDetails'
import pagination from '../Utils/Paginate'
import spinner from '../Utils/ClipLoader'
import { stripFilename, getTraceLink, disableOpenButton } from '../Utils/Global.js'

export default {
  data: () => ({
    collection: {},
    searchFields: {
      sort: {
        sortName: 'created',
        sortOrder: 'DESC'
      },
      textFilter: ''
    },
    selectedAlertDescription: '',
    selectedFirmwareRevision: '',
    selectedAlertKey: '',
    selectedSymptomsList: {},
    selectedPayloadList: {},
    paginate: {
      total: 100,
      pageSize: 50,
      currentPage: 1
    },
    isLoading: true,
    hasErrors: false,
    errorMessage: '',
    stripFilename: stripFilename,
    getTraceLink: getTraceLink,
    disableOpenButton: disableOpenButton
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }

    const query = this.$route?.query

    if (query.deviceId) {
      this.searchFields.textFilter = 'deviceKey:' + query.deviceId
    } else if (query.issueKey) {
      this.searchFields.textFilter = 'issue:' + query.issueKey
    } else if (query.alertType) {
      this.searchFields.textFilter = 'alertType:' + query.alertType
    }

    this.getTableData(1)
    const that = this

    $('#viewDetails').on('show.bs.modal', function (event) {
      const index = $(event.relatedTarget).data('row')
      that.selectedAlertKey = that.collection[index].alertKey
      that.selectedSymptomsList = that.collection[index].symptoms
      if (that.collection[index].version === '2022-07-06') {
        that.selectedPayloadList = that.collection[index].payloads
      }
      that.selectedAlertDescription = that.collection[index].alertDescription
      that.selectedFirmwareRevision = that.collection[index].firmwareRevision
    })
  },
  methods: {
    getTableData: function (page) {
      if (typeof page === 'undefined') {
        page = 1
      }

      this.isLoading = true

      let queryUri =
        '?page=' + page +
        '&sortName=' +
        this.searchFields.sort.sortName +
        '&sortOrder=' +
        this.searchFields.sort.sortOrder

      if (!(/^\s*$/).test(this.searchFields.textFilter)) {
        queryUri += '&textFilter=' + encodeURIComponent(this.searchFields.textFilter)
      }

      window.axios.get(this.$store.state.auth.serviceEndpoint + '/alerts' + queryUri)
        .then(response => {
          this.collection = response.data.alerts
          this.paginate = response.data.pagination
          this.selectedSymptomsList = {} /* Clear symptoms list in case of */
          this.selectedPayloadList = {}
          this.isLoading = false
          this.collection = this.collection.map(a => {
            const alert = a
            if (a.version === '2019-07-06') {
              alert.alertKey = a.traceKey
            }
            return alert
          })
        }).catch(error => {
          console.log(error.message)
          this.isLoading = false
          this.hasErrors = true
          this.errorMessage = 'Failed to get Alerts: ' + error.message
        })
    },
    sortBy: function (field, event) {
      this.loading = true
      // Reset all to bottom
      if (field !== this.searchFields.sort.sortName) {
        $('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
        this.searchFields.sort.sortOrder = 'DESC'
      }
      this.searchFields.sort.sortName = field
      if (this.searchFields.sort.sortOrder === 'ASC') {
        this.searchFields.sort.sortOrder = 'DESC'
        $(event.currentTarget)
          .find('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
      } else {
        this.searchFields.sort.sortOrder = 'ASC'
        $(event.currentTarget)
          .find('.oi.oi-caret-bottom')
          .removeClass('.oi.oi-caret-bottom')
          .addClass('oi-caret-top')
      }
      return this.getTableData(this.currentPage)
    },
    clearFilter: function () {
      this.searchFields.textFilter = ''
      return this.getTableData(this.currentPage)
    },
    performFilter: function () {
      return this.getTableData(this.currentPage)
    }
  },
  components: {
    spinner,
    pagination,
    AlertDetails
  }
}
</script>
