<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DFM CrashCatcher integration
    </h2>
    <div class="help-section">
      <p>
        DevAlert allows for providing any kind of diagnostic data (“payloads”) within an alert.
        For including crash dumps on Arm Cortex-M device including a stack trace and registers, we include an example using
        <a href="https://github.com/adamgreen/CrashCatcher" target="_blank" rel="noopener">CrashCatcher</a>.
        This is called from the hardfault handler and saves registers and selected regions of memory (e.g. the stack) in binary form and provides this as a DFM alert.
      </p>
      <p>
        On the host side, <a href="https://github.com/adamgreen/CrashDebug" target="_blank" rel="noopener">CrashDebug</a> is used to parse the crash dump and analyze it using GDB,
        either interactively from the GDB command prompt or using a text file generated by GDB.
        It is also possible to connect an IDE debugger with GDB support to the GDB session and inspect the crash dump this way.
      </p>
      <p>
        <router-link :to='{ name: "Dispatcher"}'>DevAlert Dispatcher</router-link> includes example scripts for starting a GDB server with CrashDebug to inspect a CrashCatcher dump.
        This can be modified as needed.
      </p>
      <p>
        CrashCatcher is implementing a <samp>HardFault_Handler</samp> which is calling CrashCatcher, that in turn is calling the callbacks implemented in <i>dfmCrashCatcher.c</i> to generate the alert.
      </p>
    </div>

    <div class="help-section">
      <h4>
        Configuration
      </h4>
      <p>
        The DFM CrashCatcher integration can be tweaked with some configuration parameters defined in <i>dfmCrashCatcher.h</i>.
      </p>
      <p>
        By default a trace is added to the alert, this can be turned off by setting <samp>CRASH_ADD_TRACE</samp> to 0.
      </p>
      <p>
        The memory region(s) to dump need to be specified in <samp>CRASH_MEM_REGION1_START</samp>
        (and the size in <samp>CRASH_MEM_REGION1_SIZE</samp>),
        the region should contain the stack in order for CrashDebug to create a stack trace.
        There are defines for 3 regions, just set them to 0 in case not all of them are needed.
        <pre>
    #define CRASH_MEM_REGION1_START 0x10002000
    #define CRASH_MEM_REGION1_SIZE  0x800
    #define CRASH_MEM_REGION2_START 0
    #define CRASH_MEM_REGION2_SIZE  0
    #define CRASH_MEM_REGION3_START 0x0
    #define CRASH_MEM_REGION3_SIZE  0
        </pre>
      </p>
      <p>
        The size of the crash dump buffer can be defined with <samp>CRASH_DUMP_MAX_SIZE</samp>.
        Note that the memory region needs to fit in the buffer together will additional data for storing registers etc.
      </p>
      <p>
        Further documentation about CrashCatcher and CrashDebug can be found on the projects Github pages.
      </p>
    </div>

    <div class="help-section">
      <h4>
        Integrating CrashCatcher on Target
      </h4>
      <p>
        Make sure that the following <a href="https://github.com/adamgreen/CrashCatcher" target="_blank" rel="noopener">CrashCatcher</a> files are included in your project.
        <pre>
    Core/src/CrashCatcher.c
    Core/src/CrashCatcherPriv.h
    Core/src/CrashCatcher_armv6m.S or Core/src/CrashCatcher_armv7m.S
        </pre>
        Read the CrashCatcher documentation on <a href="https://github.com/adamgreen/CrashCatcher#linking-to-crashcatcher-libraries">how to link</a>
        and how to make the linker use the fault handler provided by CrashCatcher.
      </p>
    </div>

    <div class="help-section">
      <h4>
        Inspecting Crash Dumps using GDB and CrashDebug
      </h4>
      <p>
        When you click on a “crash.dmp” link in DevAlert, the crash dump is downloaded by the DevAlert Dispatcher and launched in the specified tool.
        Note that Dispatcher must be installed locally and <router-link :to='{ name: "DispatcherExamples"}'>configured accordingly</router-link>.
      </p>
      <p>
        Note that the correct .elf file must be included in the CrashDebug parameters.
        This can be fetched from your local network or a private S3 bucket as part of the Dispatcher script using the <samp>${revision}</samp> attribute.
        This corresponds to the revision string provided in DFM.
      </p>
      <p>
        As can be seen in the example scripts, CrashDebug is started by launching a GDB server with the following arguments:
        <pre>
$ gdb &lt;elf-file&gt; -ex "set target-charset ASCII" -ex "target remote | CrashDebug --elf &lt;elf-file&gt; --dump &lt;crash-dump-file&gt;"
        </pre>
      </p>
      <p>
        It is recommended to use the same installation of GDB as you are using in your regular debugging.
        If not using GDB explicitly, most Eclipse-based tools uses it under the hood.
        Moreover, OpenOCD provided a GDB interface that can be used together with IAR Embedded Workbench and other tools.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
