<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Invalid state in application code
    </h2>
    <div class="help-section">
      <p>
        Most applications have one or more "state" variables, and if they have the wrong value it indicates a problem in the code.
        These are often handled by having a default fallback in a switch statement, or else catch for an if statement,
        which will reset the value of the state to a valid one.
        In these cases it is suitable to add a non-critical Alert, which will help the developers know if this happens very rarely
        or very often.
      </p>
    </div>
    <h4>
      Preparation
    </h4>
    <div class="help-section">
      <p>
        To add a custom alert, use the <router-link :to='{ name: "AlertTypes"}'>Alert Types</router-link> to check if there already is
        a suitable alert, and if not add a new one using <router-link :to='{ name: "AlertTypes"}'>Add new Alert Type</router-link>.
      </p>
      <p>
        <b>Note!</b> Only users with <i>Contributor</i> or <i>Owner</i> role can add alert types.
      </p>
    </div>
    <h4>
      Example code
    </h4>
    <div class="help-section">
      <pre><code>
  /* Function called by main thread to do one "step" */
  void vDoNextStep()
  {
    switch(myState)
    {
      case INITIAL:
        doInitialStep();
        break;

      case STATE_1:
        doStep1();
        break;

      case STATE_2:
        doStep2();
        break;

      case STATE_3:
        doStep3();
        break;

      default:
        DfmAlertHandle_t xAlertHandle;
        /* Create the alert, with details, an alert handle is returned */
        if (xDfmAlertBegin(DFM_ALERT_UNHANDLED_STATE, "Unhandled state detected", &xAlertHandle) == DFM_SUCCESS)
        {
            /* Add alert symptoms */
            xDfmAlertAddSymptom(xAlertHandle, DFM_SYMPTOM_CURRENT_STATE, myState );

            /* Disable tracing */
            xTraceDisable();

            /* Add trace payload */
            prvAddTracePayload(xAlertHandle);

            /* Send the alert */
            xDfmAlertEnd(xAlertHandle);

            /* Enable tracing */
            xTraceEnable();
        }

        /* Restore state to a valid value */
        myState = INITIAL;
        break;
    }
  }

  static void prvAddTracePayload(DfmAlertHandle_t alertHandle)
  {
      char* szName;
      void* pvBuffer = (void*)0;
      uint32_t ulBufferSize = 0;
      if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_STREAMING)
      {
          szName = "dfm_trace.psfs";
      }
      else if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_SNAPSHOT)
      {
          szName = "dfm_trace.bin";
      }

      xTraceGetEventBuffer(&pvBuffer, &ulBufferSize);
      xDfmAlertAddPayload(alertHandle, pvBuffer, ulBufferSize, szName);
  }
        </code></pre>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
        -
        <router-link :to='{ name: "DfmExamples"}'>Back to Examples</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
