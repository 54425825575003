<template>
  <v-app>
    <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Percepio Tracealyzer&#174;
    </h2>
    <div class="help-section">
      <p>
        To open attached traces from alerts, Percepio Tracealyzer is required.
      </p>
      <p>
        You will find the latest version to download <a href="https://percepio.com/tracealyzer/update/">here</a>
        <a target="_blank" rel="noopener noreferrer" href="https://percepio.com/tracealyzer/update/">
          <v-icon style="font-size: 14px">mdi-open-in-new</v-icon>
        </a>.
      </p>
      <p>
        For help on setting up Tracealyzer, visit the <a href="https://percepio.com/tracealyzer/gettingstarted/">Getting Started with Tracealyzer</a> page.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
  </v-app>
</template>

<script>
import { VApp, VIcon } from 'vuetify/components'
export default {
  data: () => ({
  }),
  components: {
    VApp,
    VIcon
  }
}
</script>
