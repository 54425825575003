<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setting up Device Backend using Microsoft Azure
    </h2>
    <div class="help-section">
      <p>
        Percepio provides deploy scripts for running on Linux or Windows, along with a configuration file with all settings needed
        to have the Device Backend connect to the Percepio Devalert Service. Running the script without arguments will list more information on
        what arguments must be provided, and what others can be overridden (e.g which Azure location to use).
      </p>
      <p>Tracealyzer need permission to read the traces from a deployed blob container. Most of this is handled by the deploy
         script. However, there are a few things to consider. The reqiured permission to read traces will be set on an
         Azure AD group specified as a parameter to the deploy command. So before running the script create this group
         in Azure Portal (or Azure CLI) or use an existing group in your Azure subscription. The group need to be an AD
         security group. The deploy script will create a role assignment that will assign the Blob Data Reader role to the
         group and hence the user running the deploy script need to have permission to make role assignment, e.g. the Owner role.
      </p>
      <p>
        The scripts rely on Azure Command Line Interface (Azure CLI) for setting up the resources, see the
        <a href="https://docs.microsoft.com/en-us/cli/azure/install-azure-cli/">Azure CLI documentation</a> for help on setup and usage.
      </p>
      <p>
        The script also uses <b>jq</b> tool for handling inputs and outputs, see the
        <a href="https://stedolan.github.io/jq/download/">jq download page</a> for help on setup and usage.
      </p>
      <p>
        All resources needed for sending Alerts to DevAlert are deployed in a resource group defined by the deploy script parameter
        <code>--resource-group</code>. The resource group will be created if the <code>create</code>-command is given and in case
        one want to deploy into an existing resource group one can use the <code>update</code>-command. Update is also used to update
        deployment in case there are new versions of the resource template or Functions.
      </p>
    </div>
    <div class="help-section">
      <h4>Certificate</h4>
      <p>
        In the end of the deployment a TLS certificate will be created. This certificate is going to be used to autheticate
        the alert sender when sending the alerts to the DevAlert Service. The public certificate will by default be saved to the file
        <code>truststore.pem</code>. When the certificate is successfully created it should be uploaded to the DevAlert Service
        on the <router-link :to='{ name: "Certificate"}'>Certificate upload page</router-link> which you find under the Settings menu.

        The private keys for the certificate are kept in an Azure Keystore deployed in your Azure resource group and will
        hence never leave your organization.
      </p>
    </div>
    <h4 class="with-actions">
      Device Backend error handling
    </h4>
    <div class="help-section">
      <p>
        In case there are errors when seding alerts to DevAlert Service the alerts are stored in an Azure Service Bus Queue. The
        alerts can then be resent using the deployed Function <code>RetryAlertSender</code> at a later stage from Azure Portal
        or using curl.
        Follow these steps to retry sending the queued alerts using Azure Portal:
        <ol>
         <li>Go to the <code>RetryAlertSender</code> Function in the resource group where Device Backend was deployed</li>
         <li>Select <code>Code + Test</code> then <code>Test/Run</code></li>
         <li>Use <code>GET</code> HTTP-method and any of the keys and finally click "Run"-button</li>
        </ol>
      </p>
    </div>
    <h4>Script usage examples</h4>
    <div class="help-section">
      <p>
        Get help by running:<br>
        <code>$ ./devalert_deploy.sh help</code>
      </p>
      <p>
        An example how to deploy:<br>
        <code>$ ./devalert_deploy.sh create --resource-group devalert-demo --template-file devalert_devicebackend.json --settings-file devicebackend_template_settings.json --storage-account percepio</code>
      </p>
      <p>
        If you need to download the certificate that was created when deploying:<br>
        <code>$ ./devalert_deploy.sh download-cert --resource-group devalert-demo</code>
      </p>
      <p>
        If you need to create a new certificate, e.g. to update a soon expired certificate:<br>
        <code>$ ./devalert_deploy.sh create-cert --resource-group devalert-demo</code>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
