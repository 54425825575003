<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setting up Percepio DevAlert Dispatcher
    </h2>

    <h3>
      Provider Settings Wizard
    </h3>
    <div class="help-section">
      <p>
        To run the Provider Settings Wizard (using the button on the Settings tab) you must have your DevAlert login (same as for this site),
        and know your <b>Backend provider</b> settings, which are handled by your own company cloud administrator.
      </p>
    </div>

    <h4>
      DevAlert Service Login
    </h4>
    <div class="help-section">
      <p>
        For the DevAlert Service login, use the same email and password that are used to log in to this site.
        You can request to save the password (stored encrypted on your machine), or to enter it every time you launch the Dispatcher.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_da_service.png" />
      <p>
        To verify that you have entered correct information, use the <i>Test</i> button.
      </p>
    </div>

    <h4 v-if="showAws">
      Backend Provider, Amazon Web Services
    </h4>
    <div class="help-section" v-if="showAws">
      <p>
        If you have a direct login to Amazon Web Services (AWS) using their Identity and Access Management (IAM),
        this is the provider to use. You can <i>Manage</i> your AWS profiles directly from the Dispatcher settings,
        including adding new profiles and importing new credentials, but the setup details is managed by your own
        company cloud administrator.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_backend_aws.png" />
      <p>
        If you have received a settings file from your administrator, use the Import AWS Settings button.
        If not, enter the information manually.
      </p>
    </div>
    <h5 class="with-actions" v-if="showAws">
      Import Settings
    </h5>
    <div class="help-section" v-if="showAws">
      <p>
        Select a profile and region, and click the <i>Import AWS Settings</i> button at the top to browse for your settings file,
        default name is <i>Customer_Settings.json</i>. Use the <i>Test Connection</i> button to verify the connection details.
        Finally use the <i>Test Bucket Access</i> to verify that the bucket can be accessed correctly.
      </p>
    </div>
    <h5 class="with-actions" v-if="showAws">
      Manual Setup
    </h5>
    <div class="help-section" v-if="showAws">
      <p>
        Select a profile and region, add role to assume (if needed) and external ID matching that role (if provided),
        and verify the information is correct with the <i>Test Connection</i> button.
      </p>
      <p>
        When you can connect, you can <i>Browse</i> for Trace Bucket in the selected region, or enter the name manually.
        The Trace Prefix should generally be left empty, when DevAlert files are stored directly in the root of the S3 bucket,
        but if the bucket is shared between DevAlert projects you might have to set a prefix to know where to find the files.
        The <i>Suggest</i> button will try to fetch a few files from the selected bucket and check if there seems to be a prefix.
        Finally use the <i>Test Bucket Access</i> to verify that the bucket can be accessed correctly.
      </p>
    </div>

    <h4 v-if="showAzure">
      Backend Provider, Microsoft Azure
    </h4>
    <div class="help-section" v-if="showAzure">
      <p>
        If you have a direct login to Microsoft Azure this it the provider to use.
        The information in this section comes from your cloud administrator.<br/>
        If you have received a settings file from your administrator, use the Import Azure Settings button.
        If not, enter the information manually.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_backend_azure.png" />
      <p>
        The <i>Test Connection</i> button will verify you have access to the blob container in the storage account.
      </p>
    </div>
    <h5 class="with-actions" v-if="showAzure">
      Import Settings
    </h5>
    <div class="help-section" v-if="showAzure">
      <p>
        Click the <i>Import Azure Settings</i> button at the top to browse for your settings file,
        default name is <i>Customer_Settings.json</i>. Use the <i>Test Connection</i> button to verify the connection details.
      </p>
    </div>
    <h5 class="with-actions" v-if="showAzure">
      Manual Setup
    </h5>
    <div class="help-section" v-if="showAzure">
      <p>
        Enter the name of your Storage Account and click the <i>Load...</i> button to connect to Azure and fetch
        the availabe Blob Containers for the Storage Account.<br/>
        Select the correct Blob Container, and verify the information is correct with the <i>Test Connection</i> button.
      </p>
      <p>
        <b>Note!</b> When you change Storage Account the Dispatcher will try to load Containers after a timeout of 5 seconds.
      </p>
    </div>

    <h4>
      Backend Provider, DevAlert Evaluation
    </h4>
    <div class="help-section">
      <p>
        These settings are the same as the Service Login, and should already be filled in when the wizard page is loaded.
        The <i>Test</i> button will not only verify that your email and password are valid, but also use them to connect and verify
        that a DevAlert Evaluation is active.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_backend_da_eval.png" />
      <p>
        <b>Note!</b> If you change the settings here, you will also change the Service Login settings,
        including if the password shall be remembered or not.
      </p>
    </div>

    <h4 v-if="showLocal">
      Backend Provider, Local Storage
    </h4>
    <div class="help-section" v-if="showLocal">
      <p>
        Browse for any path that you can reach on your computer. It can be a local drive, a shared network drive,
        or even a folder that uses cloud synchronization to distribute files in your company.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_backend_local.png" />
      <p>
        The <i>Test</i> button will verify you have read access to the folder.
      </p>
    </div>

    <h4 v-if="showLocal">
      Final page
    </h4>
    <div class="help-section" v-if="showLocal">
      <p>
        When you reach the final page of the Wizard, which lists all changes made in the Wizard, you can chose to either <i>Finish</i> and save the settings,
        go back with <i>Previous</i> to modify settings as needed, or discard any changes with <i>Cancel</i>.
      </p>
    </div>

    <h3>
      Additional Dispatcher Settings
    </h3>
    <div class="help-section">
      <p>
        From your Settings tab in the Dispatcher you can select <i>Change Dispatcher Settings...</i> to get access to additional settings.
      </p>
    </div>
    <h4>
      General Settings
    </h4>
    <div class="help-section">
      <p>
        This section contains the general settings for the Dispatcher.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_settings.png" />
      <p>
        Change the Launch policy, i.e. what the Dispatcher should do when a file has successfully been downloaded and launched.
        <ul>
          <li><i>Close after Launch</i>, will always close the Dispatcher when a file is launched.</li>
          <li><i>Keep Open after Launch</i>, will always keep the Dispatcher when a file is launched.</li>
          <li>
            <i>Keep Open if additional Payloads are found</i>, will close if there are no other Payloads of the selected Issue, or keep open if there are.<br/>
            This is the default option.
          </li>
        </ul>
        <b>Note!</b> If the Dispatcher has problems, it will always keep it open so the information can be seen.
      </p>
      <p>
        The Cache Directory is set to a Dispatcher folder in your Application Data folder by default,
        but can be modified to save in any folder instead.<br/>
        The Cache Retention is how many days files should be kept after they have been <i>read</i>, i.e. the time will reset if you open the same file again.
      </p>
      <p>
        The URL Scheme Handler is important to check if the Dispatcher is set to handle DevAlert links or not.
        <i>Refresh</i> to check the current status, and if not registerd try <i>Update URL Scheme Status</i>.
      </p>
    </div>
    <h4>
      File Mappings
    </h4>
    <div class="help-section">
      <p>
        In order for the Dispatcher to know how to handle files fetched from DevAlert Service, the File Mappings are used.
        Here you can set up a Mapping to handle certain file extensions, specific filenames, or a general Catch All that is used if no other mapping is found.<br/>
        <b>Note!</b> Only one successful file mapping will be used on each launch of a fetched file.
      </p>
      <img class="img-dispatcher" src="@/assets/images/dispatcher_file_mappings.png" />
      <p>
        If there is no mapping found that matches a fetched file, it will launch with <b>Open</b>, i.e. the same way as if you had double clicked the file
        in your file explorer. This will allow common files, like text files, to be opened without having to set up any explicit mapping for it.
      </p>
      <p>
        The order of the Mappings in the list at the left side of the screen is the priority order in which the mappings are checked.
        However, all mappings for <i>Exact Filename</i> will always be checked first, then the <i>Extension</i> mappings, and finally the <i>Catch All</i> mappings.
      </p>
      <p>
        When the Dispatcher is setup initially it will check if Tracealyzer&#174; is installed in the default directory,
        and if so it will setup mappings for this automatically.
      </p>
      <p>
        To add a new mapping, simply click the <i>New Mapping</i> button. You can also use <i>Template Mapping</i> to fetch one of the default mappings as a start.<br/>
        Give the mapping a new name, and select what type to use.
        <ul>
          <li><i>Exact Filename</i> must match the whole filename, but is case insensitive.</li>
          <li><i>Extension</i> only check the file extension, and is also case insensitive.</li>
          <li><i>Catch All</i> will match all files.</li>
        </ul>
        If either <i>Exact Filename</i> or <i>Extension</i>, enter it in the text box next to the option.<br/>
        <b>Note!</b> For <i>Extension</i>, only write the part <i>after</i> the dot, e.g. to map &quot;*.file&quot; only write &quot;file&quot; in the text box.
      </p>
      <p>
        Enter or <i>Browse</i> for the executable to use. This should be a binary executable or a script.<br/>
        Depending on what is selected, the <i>Create Window</i> option is suitable. This only affects things like command line tools,
        which normally launch in a terminal, and not real applications that create windows.<br/>
        Some scripts can also need to be run in an explicit terminal, in which case <i>Use Shell Executable</i> can help.
      </p>
      <p>
        The default Startup Folder is the same as the executable, but can be changed if needed.
      </p>
      <p>
        By default the mapping will launch with the full filename that was fetched as the only parameter.<br/>
        Some scripts might need to do additional processing before launching, and for this it is possible to add additional parameters.
        The parameters will be used as entered, with the exception of the listed mapping parameters listed below.
        If for example an additional parameter of '/open' is needed before the filename, enter this in the Parameters box:<br/>
        <samp>/open &#36;&#123;file&#125;</samp><br/>
        The result will replace <i>&#36;&#123;file&#125;</i> with the actual filename used.
      </p>
    </div>
    <h4>
      Scripts
    </h4>
    <div class="help-section">
      <p>
        Some systems require additional setup, like fetching files from a repository, or copy or renaming configuration files.
        To support this, the Dispatcher have support for <i>Scripts</i>.
      </p>
      <p>
        The setup is the same as for file mappings, including the parameters and their replacements.
        The big difference between a Script and a Mapping is that if a file matches more than one Script,
        <i>all</i> of them will be launched, including <i>Catch All</i> scripts, while for Mappings
        only the first <i>successful</i> mapping launch will be used.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
        -
        <router-link :to='{ name: "Dispatcher"}'>Back to Dispatcher</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showAws: true,
    showAzure: true,
    showLocal: true,
    showOldDownloads: false
  }),
  computed: {
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    }
  },
  mounted () {
    this.showAws = this.$store.state.auth.filterTags.includes('AWS')
    this.showAzure = this.$store.state.auth.filterTags.includes('Azure') && this.hasDeveloperFeature
    this.showLocal = this.$store.state.auth.filterTags.includes('Local')
  }
}
</script>
