<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Getting Started Guide
    </h2>
    <div class="help-section">
      <p>
        DevAlert consists of three different parts; the Device, the <i>Device Backend</i> (your cloud solution), and the Percepio DevAlert Service.
        The diagram below shows how these parts work together.
      </p>
      <div style="width:50%;margin:8px;">
        <p>
        </p>
        <p>
          <img src="@/assets/images/DevAlert_Overview.png" alt="overview" style="width:100%;height:100%;object-fit:cover;" />
        </p>
      </div>
      <p>
        To get more information about how DevAlert is setup, see
        <a href="https://aws.amazon.com/blogs/apn/detecting-bugs-in-deployed-iot-devices-using-percepio-devalert/">our APN Blog</a>
        or visit our
        <a href="https://percepio.com/devalert">DevAlert product page</a>.
      </p>
      <p>
        For a complete DevAlert setup, follow these steps.
      </p>
      <p>
      <ol>
        <li><router-link :to='{ name: "StartupPercepio"}'>Percepio DevAlert Service</router-link></li>
        <li>
          <router-link :to='{ name: "StartupCustomer"}'>Setup Device Backend</router-link>
          <ul>
            <li v-if="showAws"><router-link :to='{ name: "StartupCustomerAws"}'>Amazon Web Services (AWS)</router-link></li>
            <li v-if="showAzure"><router-link :to='{ name: "StartupCustomerAzure"}'>Microsoft Azure</router-link></li>
            <li v-if="showLocal"><router-link :to='{ name: "StartupCustomerLocalStorage"}'>Local Storage</router-link></li>
          </ul>
        </li>
        <li>
          <router-link :to='{ name: "StartupDevice"}'>Setup Device (target) with intended RTOS</router-link>
          <ul>
            <li v-if="showFreeRTOS"><router-link :to='{ name: "StartupDeviceFreeRTOS"}'>FreeRTOS</router-link></li>
            <li v-if="showThreadX"><router-link :to='{ name: "StartupDeviceAzureRTOS"}'>Azure RTOS ThreadX</router-link></li>
            <li v-if="showBareMetal"><router-link :to='{ name: "StartupDeviceBareMetal"}'>Other RTOS</router-link></li>
          </ul>
        </li>
        <li>
          <router-link :to='{ name: "StartupCloud"}'>Setup Device connection to the Device Backend</router-link>
          <ul>
            <li v-if="showAws"><router-link :to='{ name: "StartupCloudAwsMQTT"}'>AWS using MQTT</router-link></li>
            <li v-if="showAzure"><router-link :to='{ name: "StartupCloudAzureMQTT"}'>Azure using MQTT</router-link></li>
            <li v-if="showLocal"><router-link :to='{ name: "StartupCloudRTT"}'>Using SEGGER J-Link to local PC</router-link></li>
          </ul>
        </li>
        <li>
          Setup Device trace
          <ul>
            <li v-if="showFreeRTOS"><router-link :to='{ name: "StartupTraceFreeRTOS"}'>FreeRTOS using Percepio Trace Library</router-link></li>
            <li v-if="showThreadX"><router-link :to='{ name: "StartupTraceAzureRTOS"}'>Azure RTOS using built in trace facility</router-link></li>
          </ul>
        </li>
        <li>
          Setup <router-link :to='{ name: "Dfm"}'>DevAlert Firmware Monitor (DFM)</router-link> library
          <ul>
            <li>Integrate <router-link :to='{ name: "Dfm"}'>DevAlert Firmware Monitor (DFM)</router-link> library</li>
            <li>Follow <router-link :to='{ name: "AlertKeys"}'>Alert Key Uniqueness</router-link> rules</li>
            <li v-if="showAws">Setup <router-link :to='{ name: "DfmAwsMQTT"}'>MQTT for AWS</router-link></li>
            <li v-if="showAzure">Setup <router-link :to='{ name: "DfmAzureMQTT"}'>MQTT for Azure</router-link></li>
            <li v-if="showLocal">Setup <router-link :to='{ name: "DfmRTT"}'>RTT to local PC</router-link></li>
          </ul>
        </li>
        <li><router-link :to='{ name: "Dispatcher"}'>Percepio DevAlert Dispatcher</router-link></li>
        <li><router-link :to='{ name: "Tracealyzer"}'>Percepio Tracealyzer&#174;</router-link></li>
      </ol>
      </p>
      <p>
        When DevAlert is setup, see our <router-link :to='{ name: "DfmExamples"}'>example scenarios</router-link> to get help
        integrating DevAlert into your software project.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false,
    showAws: true,
    showAzure: true,
    showLocal: true,
    showFreeRTOS: true,
    showThreadX: true,
    showBareMetal: true
  }),
  computed: {
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    }
  },
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    this.showAws = this.$store.state.auth.filterTags.includes('AWS')
    this.showAzure = this.$store.state.auth.filterTags.includes('Azure') && this.hasDeveloperFeature
    this.showLocal = this.$store.state.auth.filterTags.includes('Local')
    this.showFreeRTOS = this.$store.state.auth.filterTags.includes('FreeRTOS')
    this.showThreadX = this.$store.state.auth.filterTags.includes('ThreadX') && this.hasDeveloperFeature
    this.showBareMetal = this.$store.state.auth.filterTags.includes('BareMetal')
  }
}
</script>
