<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setup Device (target) with intended RTOS
    </h2>
    <div class="help-section">
      <p>
        Use link below to see how to set up your RTOS
      </p>
      <p>
        <ul>
          <li v-if="showFreeRTOS"><router-link :to='{ name: "StartupDeviceFreeRTOS"}'>FreeRTOS</router-link></li>
          <li v-if="showThreadX"><router-link :to='{ name: "StartupDeviceAzureRTOS"}'>Azure RTOS ThreadX</router-link></li>
          <li v-if="showBareMetal"><router-link :to='{ name: "StartupDeviceBareMetal"}'>Other RTOS</router-link></li>
        </ul>
      </p>
      <p>
        Also remember to ensure the device follow the <router-link :to='{ name: "AlertKeys"}'>Alert Key Uniqueness</router-link> rules.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false,
    showFreeRTOS: true,
    showThreadX: true,
    showBareMetal: true
  }),
  computed: {
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    }
  },
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    this.showFreeRTOS = this.$store.state.auth.filterTags.includes('FreeRTOS')
    this.showThreadX = this.$store.state.auth.filterTags.includes('ThreadX') && this.hasDeveloperFeature
    this.showBareMetal = this.$store.state.auth.filterTags.includes('BareMetal')
  }
}
</script>
