<template>
  <div>
    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div class="table-responsive" v-if="!isLoading && !hasErrors">
        <h4>Issue Overview</h4>

        <column-preferences :column-preferences="columnPreferences" :column-preferences-cache-key="columnPreferencesCacheKey" />

        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <!--th class="sort-by" @click="sortBy('alertType', $event)">Alert type <span class="oi oi-caret-bottom"></span></th-->
              <th class="sort-by" @click="sortBy('alertTypeDescription', $event)">Description <span class="oi oi-caret-bottom"></span></th>
              <th v-if="this.columnPreferences['revision']['value']" class="sort-by" @click="sortBy('firmwareRevision', $event)">Revision <span class="oi oi-caret-bottom"></span></th>
              <!--th v-if="this.columnPreferences['issue']['value']" class="sort-by" @click="sortBy('issueKey', $event)">Issue <span class="oi oi-caret-bottom"></span></th-->
              <th v-if="this.columnPreferences['count']['value']">Count</th>
              <th v-if="this.columnPreferences['last_occurence']['value']" class="sort-by" @click="sortBy('lastUpdate', $event)">Latest Occurrence <span class="oi oi-caret-bottom"></span></th>
              <th v-if="this.columnPreferences['device_id']['value']" class="sort-by" @click="sortBy('deviceDescription', $event)">Device Id <span class="oi oi-caret-bottom"></span></th>
              <th v-if="this.columnPreferences['symptoms']['value']">Symptoms</th>
              <th v-if="this.columnPreferences['payloads']['value']">Payloads</th>
              <th>Details</th>
              <th>Alerts</th>
              <!--th>Latest Trace</th-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dataRow, index) in collection" :key="index">
              <!--td>{{ dataRow.alertType }}</td-->
              <td>{{ dataRow.alertTypeDescription }}</td>
              <td v-if="this.columnPreferences['revision']['value']">{{ dataRow.firmwareRevision }}</td>
              <!--td v-if="this.columnPreferences['issue']['value']">{{ dataRow.issueKey }}</td-->
              <td v-if="this.columnPreferences['count']['value']">{{ dataRow.alertCount }}</td>
              <td v-if="this.columnPreferences['last_occurence']['value']" v-bind:class='{ "new-issue": isNewAlert(dataRow.lastUpdate), "older-issue": isOlderAlert(dataRow.lastUpdate) }'>{{ $global.toLocalTime(dataRow.lastUpdate) }}</td>
              <td v-if="this.columnPreferences['device_id']['value']">{{ dataRow.deviceDescription }}</td>
              <td v-if="this.columnPreferences['symptoms']['value']">
                  <p v-for="symptom in collection[index].symptoms" :key='symptom.id' style="margin: 0; padding: 0;"> {{ symptom.description }}: {{ symptom.value }} </p>
              </td>
              <td v-if="this.columnPreferences['payloads']['value']">
                <p v-for="payload in collection[index].payloads" :key="payload.key" style="margin: 0; padding: 0;">
                  <a :href="'percepio://devalert/issue/' + stripFilename(dataRow.alertKey) + '/' + payload.key" @click="showLoader(payload)">
                    {{ !payload.isLoading ? payload.filename : '' }}
                    <div v-if="payload.isLoading" class="spinner"></div>
                  </a>
                </p>
              </td>
              <td>
                <button class="btn btn-secondary" data-toggle="modal" data-target="#viewDetails" :data-row="index">
                  View
                </button>
              </td>
              <td>
                <router-link class='btn btn-secondary' :to='{ name: "Alerts", query: { issueKey: dataRow.issueKey }}'>Alerts</router-link>
              </td>
              <!--td>
                <a class="btn btn-primary" v-bind:class='{ "disabled": disableOpenButton(dataRow) }' title="Open Trace in Tracealyzer" v-bind:href="getTraceLink(dataRow)">
                  Open
                </a>
              </td-->
            </tr>
          </tbody>
        </table>
        <pagination
          :total="paginate.total"
          :page-size="paginate.pageSize"
          :callback="getTableData"
          nav-class="padding-10"
          ul-class="bg-color-red"
          li-class="txt-color-blue"></pagination>
    </div>

    <!-- Modal -->
    <alert-details id="viewDetails" :alert-key="stripFilename(selectedAlertKey)" :symptoms-list="selectedSymptomsList" :payload-list="selectedPayloadList" :alert-description="selectedAlertDescription" :firmware-revision="selectedFirmwareRevision"/>

  </div>
</template>

<script>
import AlertDetails from '../Common/AlertDetails'
import ColumnPreferences from '../Common/ColumnPreferences'

import pagination from '../Utils/Paginate'
import spinner from '../Utils/ClipLoader'
import { Poller, stripFilename, getTraceLink, disableOpenButton, getColumnPreferences, showLoader } from '../Utils/Global.js'

const columnPreferencesCacheKey = 'issue_column_preferences'
const columnPreferencesDefault = {
  revision: {
    name: 'revision',
    title: 'Revision',
    value: true
  },
  count: {
    name: 'count',
    title: 'Count',
    value: true
  },
  last_occurence: {
    name: 'last_occurence',
    title: 'Last Occurrence',
    value: true
  },
  device_id: {
    name: 'device_id',
    title: 'Device Id',
    value: false
  },
  symptoms: {
    name: 'symptoms',
    title: 'Symptoms',
    value: true
  },
  payloads: {
    name: 'payloads',
    title: 'Payloads',
    value: true
  }
}

export default {
  data: () => ({
    collection: {},
    searchFields: {
      sort: {
        sortName: 'lastUpdate',
        sortOrder: 'DESC'
      }
    },
    selectedAlertDescription: '',
    selectedFirmwareRevision: '',
    selectedAlertKey: '',
    selectedSymptomsList: {},
    selectedPayloadList: {},
    paginate: {
      total: 1000,
      pageSize: 50,
      currentPage: 1
    },
    isLoading: true,
    hasErrors: false,
    errorMessage: '',
    poller: null,
    intervalMs: 5000,
    stripFilename: stripFilename,
    getTraceLink: getTraceLink,
    disableOpenButton: disableOpenButton,
    showLoader: showLoader,
    columnPreferencesCacheKey: 'issue_column_preferences',
    columnPreferences: getColumnPreferences(columnPreferencesDefault, columnPreferencesCacheKey
    )
  }),
  computed: {
    loadPage: function () {
      return this.isAuthenticated && (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  watch: {
    isAuthenticated: function () {
      if (!this.$store.state.auth.authenticated) {
        this.poller.stop()
      }
    }
  },
  mounted () {
    if (!this.loadPage) {
      return
    }
    this.poller = new Poller(this.intervalMs)
    this.pollTableData(1)
    const that = this
    $('#viewDetails').on('show.bs.modal', function (event) {
      const index = $(event.relatedTarget).data('row')
      that.selectedAlertKey = that.collection[index].alertKey
      that.selectedSymptomsList = that.collection[index].symptoms
      if (that.collection[index].version === '2022-08-24') {
        that.selectedPayloadList = that.collection[index].payloads
      }
      that.selectedAlertDescription = that.collection[index].alertDescription
      that.selectedFirmwareRevision = that.collection[index].firmwareRevision
    })
  },
  methods: {
    leave: function () {
      if (this.poller) {
        this.poller.stop()
      }
    },
    getTableData: function (page) {
      if (typeof page === 'undefined') {
        page = 1
      }

      const queryUri =
        '?page=' + page +
        '&sortName=' +
        this.searchFields.sort.sortName +
        '&sortOrder=' +
        this.searchFields.sort.sortOrder

      window.axios.get(this.$store.state.auth.serviceEndpoint + '/issues' + queryUri)
        .then(response => {
          this.collection = response.data.issues
          this.paginate = response.data.pagination
          this.selectedDetails = {} /* Clear details list in case of */
          this.selectedSymptomsList = {} /* Clear symptoms list in case of */
          this.selectedPayloadList = {}
          this.isLoading = false
          this.collection = this.collection.map(i => {
            const issue = i
            if (i.version === '2019-08-16') {
              issue.alertKey = i.traceKey
              issue.alertCount = i.traceCount
            }
            return issue
          })
        }).catch(error => {
          window.console.error(error.message)
          this.isLoading = false
          this.errorMessage = 'Failed to get issues: ' + error.message
          this.hasErrors = true
        })
    },
    pollTableData: function (page) {
      if (typeof page === 'undefined') {
        page = 1
      }

      this.poller.preGet = () => {
        this.poller.qp =
          '?page=' + this.paginate.currentPage +
          '&sortName=' +
          this.searchFields.sort.sortName +
          '&sortOrder=' +
          this.searchFields.sort.sortOrder
      }

      this.poller.get(this.$store.state.auth.serviceEndpoint + '/issues', (response) => {
        if (this.isDispatcherLoading()) {
          return false
        }
        this.collection = response.data.issues
        this.paginate = response.data.pagination
        this.isLoading = false
        this.collection = this.collection.map(i => {
          const issue = i
          if (i.version === '2019-08-16') {
            issue.alertKey = i.traceKey
            issue.alertCount = i.traceCount
          }
          return issue
        })
        return false
      }).catch(error => {
        window.console.error(error.message)
      })
    },
    sortBy: function (field, event) {
      this.loading = true
      // Reset all to bottom
      if (field !== this.searchFields.sort.sortName) {
        $('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
        this.searchFields.sort.sortOrder = 'DESC'
      }
      this.searchFields.sort.sortName = field
      if (this.searchFields.sort.sortOrder === 'ASC') {
        this.searchFields.sort.sortOrder = 'DESC'
        $(event.currentTarget)
          .find('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
      } else {
        this.searchFields.sort.sortOrder = 'ASC'
        $(event.currentTarget)
          .find('.oi.oi-caret-bottom')
          .removeClass('.oi.oi-caret-bottom')
          .addClass('oi-caret-top')
      }
      return this.getTableData(this.paginate.currentPage)
    },
    alertAge: function (alertDateTime) {
      return Date.now() - new Date(alertDateTime).getTime()
    },
    isNewAlert: function (alertDateTime) {
      return this.alertAge(alertDateTime) < 300000
    },
    isOlderAlert: function (alertDateTime) {
      return this.alertAge(alertDateTime) >= 300000 && this.alertAge(alertDateTime) < 600000
    },
    isDispatcherLoading: function () {
      if (Array.isArray(this.collection)) {
        return this.collection.some(item =>
          item.payloads.some(payload => payload.isLoading === true)
        )
      }
      return false
    }
  },
  components: {
    spinner,
    pagination,
    AlertDetails,
    ColumnPreferences
  }
}
</script>
<style scoped>
.new-issue {
  color: red!important;
  background: lightgoldenrodyellow!important;
}
.older-issue {
  background: lightgoldenrodyellow!important;
}
</style>
