<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Device setup using Microsoft Azure RTOS ThreadX
    </h2>
    <div class="help-section">
      <p>
        Azure RTOS ThreadX is supported for DevAlert, relying on the built in trace support.
        For details on how to set it up, visit the official Microsoft Azure RTOS website, linked below.
      </p>
      <p>
        <ul>
          <li><a href="https://aka.ms/AzureRTOSDocs">Microsoft Azure RTOS documentation</a></li>
        </ul>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
