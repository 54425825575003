const state = {
  isLoading: false,
  features: [],
  featuresLoaded: false
}

const mutations = {
  setFeatures (state, features) {
    state.isLoading = false
    state.features = features
    state.featuresLoaded = true
  },
  unsetFeatures (state) {
    state.isLoading = false
    state.features = []
    state.featuresLoaded = false
  }
}

const actions = {
  loadFeatures ({ state, commit }, serviceEndpoint) {
    if (state.isLoading) {
      return
    }

    state.isLoading = true
    window.axios.get(serviceEndpoint + '/config').then(response => {
      const featureText = response.data.FEATURES
      let features = []

      if (featureText === undefined) {
        // Feature is undefined
      } else if (featureText && featureText.length > 2) {
        // Feature string found
        features = featureText.split(',')
      } else {
        // Feature is something else
      }

      commit('setFeatures', features)
    }).catch(error => {
      window.console.log('Error calling /config for features: ' + error.message)
      commit('unsetFeatures')
    })
  },
  clearFeatures ({ commit }) {
    commit('unsetFeatures')
  }
}

export default {
  state,
  mutations,
  actions
}
