import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Dashboard from '../components/Dashboard/Index.vue'
import Setup from '../components/Demo/Index.vue'

import Help from '../components/Help/Index.vue'
import Console from '../components/Help/Console.vue'

import GettingStarted from '../components/Help/GettingStarted.vue'

import Tracealyzer from '../components/Help/Tracealyzer.vue'

import AlertKeys from '../components/Help/AlertKeys.vue'

import Dispatcher from '../components/Help/Dispatcher.vue'
import DispatcherDetails from '../components/Help/DispatcherDetails.vue'
import DispatcherExamples from '../components/Help/DispatcherExamples.vue'

import StartupPercepio from '../components/Help/StartupPercepio.vue'

import StartupCustomer from '../components/Help/StartupCustomer.vue'
import StartupCustomerAws from '../components/Help/StartupCustomerAws.vue'
import StartupCustomerAzure from '../components/Help/StartupCustomerAzure.vue'
import StartupCustomerLocalStorage from '../components/Help/StartupCustomerLocalStorage.vue'

import StartupDevice from '../components/Help/StartupDevice.vue'
import StartupDeviceFreeRTOS from '../components/Help/StartupDeviceFreeRTOS.vue'
import StartupDeviceAzureRTOS from '../components/Help/StartupDeviceAzureRTOS.vue'
import StartupDeviceBareMetal from '../components/Help/StartupDeviceBareMetal.vue'

import StartupCloud from '../components/Help/StartupCloud.vue'
import StartupCloudAwsMQTT from '../components/Help/StartupCloudAwsMQTT.vue'
import StartupCloudAzureMQTT from '../components/Help/StartupCloudAzureMQTT.vue'
import StartupCloudRTT from '../components/Help/StartupCloudRTT.vue'

import StartupTraceFreeRTOS from '../components/Help/StartupTraceFreeRTOS.vue'
import StartupTraceAzureRTOS from '../components/Help/StartupTraceAzureRTOS.vue'

import Dfm from '../components/Help/Dfm.vue'
import DfmCrashCatcher from '../components/Help/DfmCrashCatcher.vue'
import DfmAwsMQTT from '../components/Help/DfmAwsMQTT.vue'
import DfmAzureMQTT from '../components/Help/DfmAzureMQTT.vue'
import DfmFiles from '../components/Help/DfmFiles.vue'
import DfmRTT from '../components/Help/DfmRTT.vue'

import DfmExamples from '../components/Help/DfmExamples.vue'
import DfmExamplesFreeRTOSMallocFail from '../components/Help/DfmExamplesFreeRTOSMallocFail.vue'
import DfmExamplesCortexMHardFault from '../components/Help/DfmExamplesCortexMHardFault.vue'
import DfmExamplesApplictionState from '../components/Help/DfmExamplesApplictionState.vue'

import PageNotFound from '../components/Errors/404.vue'

import Headers from '../components/Headers/Index.vue'

import Usage from '../components/Usage/Index.vue'

import Notifications from '../components/Notifications/Index.vue'
import NotificationsEdit from '../components/Notifications/Edit.vue'

import Alerts from '../components/Alerts/Index.vue'

import AlertTypes from '../components/AlertTypes/Index.vue'
import AlertTypesNew from '../components/AlertTypes/New.vue'
import AlertTypesEdit from '../components/AlertTypes/Edit.vue'

import AuthLogin from '../components/Auth/Login.vue'
import AuthSignup from '../components/Auth/Signup.vue'
import AuthVeryNewPassword from '../components/Auth/VerifyNewPassword.vue'
import ForgottenPassword from '../components/Auth/ForgottenPassword.vue'
import InvalidLogin from '../components/Auth/InvalidLogin.vue'

import Products from '../components/Products/Index.vue'
import ProductsEdit from '../components/Products/Edit.vue'
import ProductsNew from '../components/Products/New.vue'

import Devices from '../components/Devices/Index.vue'
import DevicesNew from '../components/Devices/New.vue'
import DevicesEdit from '../components/Devices/Edit.vue'

import Symptoms from '../components/Symptoms/Index.vue'
import SymptomsNew from '../components/Symptoms/New.vue'
import SymptomsEdit from '../components/Symptoms/Edit.vue'

import AdminUserList from '../components/Admin/UserList.vue'
import Privacy from '../components/Admin/Privacy.vue'

import Certificate from '../components/Certificate/Index.vue'

import Feedback from '../components/Feedback/Index.vue'

const routes = [
  {
    path: '/',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alerts',
    component: Alerts,
    name: 'Alerts',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/welcome',
    component: Setup,
    name: 'Setup',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/login',
    component: AuthLogin,
    name: 'AuthLogin',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/verify-new-password',
    component: AuthVeryNewPassword,
    name: 'AuthVeryNewPassword',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/signup',
    component: AuthSignup,
    name: 'AuthSignup',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/forgotten-password',
    component: ForgottenPassword,
    name: 'ForgottenPassword',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/invalid-login',
    component: InvalidLogin,
    name: 'InvalidLogin',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/notifications',
    component: Notifications,
    name: 'Notifications',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usage',
    component: Usage,
    name: 'Usage',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notifications/:id/edit',
    component: NotificationsEdit,
    name: 'NotificationsEdit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    component: Products,
    name: 'Products',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/new',
    component: ProductsNew,
    name: 'ProductsNew',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/:id/edit',
    component: ProductsEdit,
    name: 'ProductsEdit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices',
    component: Devices,
    name: 'Devices',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/headers',
    component: Headers,
    name: 'Headers',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices/new',
    component: DevicesNew,
    name: 'DevicesNew',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices/:id/edit',
    component: DevicesEdit,
    name: 'DevicesEdit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alert-types',
    component: AlertTypes,
    name: 'AlertTypes',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alert-types/new',
    component: AlertTypesNew,
    name: 'AlertTypesNew',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/alert-types/:id/edit',
    component: AlertTypesEdit,
    name: 'AlertTypesEdit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/symptoms',
    component: Symptoms,
    name: 'Symptoms',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/symptoms/new',
    component: SymptomsNew,
    name: 'SymptomsNew',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/symptoms/:id/edit',
    component: SymptomsEdit,
    name: 'SymptomsEdit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help',
    component: Help,
    name: 'Help',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/console',
    component: Console,
    name: 'Console',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpConsole: true
    }
  },
  {
    path: '/dispatcher',
    component: Dispatcher,
    name: 'Dispatcher',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/dispatcher/setup',
    component: DispatcherDetails,
    name: 'DispatcherDetails',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/dispatcher/examples',
    component: DispatcherExamples,
    name: 'DispatcherExamples',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/tracealyzer',
    component: Tracealyzer,
    name: 'Tracealyzer',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/alert-keys',
    component: AlertKeys,
    name: 'AlertKeys',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/startup',
    component: GettingStarted,
    name: 'GettingStarted',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/percepio',
    component: StartupPercepio,
    name: 'StartupPercepio',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/customer',
    component: StartupCustomer,
    name: 'StartupCustomer',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/customer/aws',
    component: StartupCustomerAws,
    name: 'StartupCustomerAws',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/customer/azure',
    component: StartupCustomerAzure,
    name: 'StartupCustomerAzure',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/customer/localstorage',
    component: StartupCustomerLocalStorage,
    name: 'StartupCustomerLocalStorage',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/device',
    component: StartupDevice,
    name: 'StartupDevice',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/device/freertos',
    component: StartupDeviceFreeRTOS,
    name: 'StartupDeviceFreeRTOS',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/device/azure-rtos',
    component: StartupDeviceAzureRTOS,
    name: 'StartupDeviceAzureRTOS',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/device/bare-metal',
    component: StartupDeviceBareMetal,
    name: 'StartupDeviceBareMetal',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/cloud',
    component: StartupCloud,
    name: 'StartupCloud',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/cloud/aws-mqtt',
    component: StartupCloudAwsMQTT,
    name: 'StartupCloudAwsMQTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/cloud/azure-mqtt',
    component: StartupCloudAzureMQTT,
    name: 'StartupCloudAzureMQTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/cloud/rtt',
    component: StartupCloudRTT,
    name: 'StartupCloudRTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/trace/freertos',
    component: StartupTraceFreeRTOS,
    name: 'StartupTraceFreeRTOS',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/startup/trace/azure-rtos',
    component: StartupTraceAzureRTOS,
    name: 'StartupTraceAzureRTOS',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpStartup: true
    }
  },
  {
    path: '/dfm',
    component: Dfm,
    name: 'Dfm',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/examples/crash-catcher',
    component: DfmCrashCatcher,
    name: 'DfmCrashCatcher',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/aws-mqtt',
    component: DfmAwsMQTT,
    name: 'DfmAwsMQTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/azure-mqtt',
    component: DfmAzureMQTT,
    name: 'DfmAzureMQTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/files',
    component: DfmFiles,
    name: 'DfmFiles',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/rtt',
    component: DfmRTT,
    name: 'DfmRTT',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/examples',
    component: DfmExamples,
    name: 'DfmExamples',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/examples/cortex-m-hard-fault',
    component: DfmExamplesCortexMHardFault,
    name: 'DfmExamplesCortexMHardFault',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/examples/free-rtos-malloc',
    component: DfmExamplesFreeRTOSMallocFail,
    name: 'DfmExamplesFreeRTOSMallocFail',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/dfm/examples/application-state',
    component: DfmExamplesApplictionState,
    name: 'DfmExamplesApplictionState',
    meta: {
      requiresAuth: true,
      helpPage: true,
      helpRecorder: true
    }
  },
  {
    path: '/admin',
    component: AdminUserList,
    name: 'Admin',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/admin/privacy',
    component: Privacy,
    name: 'AuthPrivacy',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/privacy',
    component: Privacy,
    name: 'Privacy',
    meta: {
      bodyClass: 'blue-bg',
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/certificate',
    component: Certificate,
    name: 'Certificate',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/feedback',
    component: Feedback,
    name: 'Feedback',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: PageNotFound,
    name: 'NotFoundPage',
    requiresAuth: false
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /**
   * Handle 404 page
   */
  if (to.name === 'NotFoundPage') {
    next('/')
  }

  if (to.meta.requiresAuth) {
    store.dispatch('tryAutoSignIn', to)

    next()
  } else {
    store.state.auth.isLoading = false
    next()
  }
})

export default router
