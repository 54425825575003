<template>
  <div class="form-signin">
    <h2 class="with-actions">
      Privacy Policy
    </h2>
    <div class="form-group">
      <p>
        By registering for evaluation of Percepio software products or cloud services (“the Software”) you enter an agreement with Percepio AB,
        a Swedish company with registration number 556771-9686, described in full by this page. The agreement gives you the right to access the
        Software for evaluation purposes for a limited time and gives Percepio AB and authorized partners of Percepio AB the right to contact you
        regarding your interest in Percepio products. The communication is primarily via email. In case you do not wish to receive such emails,
        it is easy to unsubscribe using the link in the email footer.
      </p>
      <p>
        We use leading cloud service providers for secure transfer, processing and storage of your DevAlert data and the contact information
        provided in the registration form. This may include US-based cloud service providers. We also process your IP address in order to identify
        and store the country, but the IP address is not stored.
      </p>
      <p>
        We track and store usage of the DevAlert Sandbox in order to detect any technical problems and ensure a good user experience.
      </p>
      <p>
        In accordance with the General Data Protection Regulation (Regulation EU 2016/279 of 27 April 2016) you have the right to request a copy
        of any information Percepio AB has registered about your person. Please contact <a href="mailto:support@percepio.com">support@percepio.com</a>
        to obtain this information. If the information is erroneous, incomplete or irrelevant, you have the right to have your information corrected.
        Finally you also have the right to request that we remove your information from our systems.
      </p>
    </div>

    <div style="mt-5" v-if="!isAuthenticated">
      <div>
        <router-link class='nav-link' :to='{ name: "Dashboard"}'>DevAlert Console</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAuthenticated: false
  }),
  mounted () {
    this.isAuthenticated = this.$store.state.auth.authenticated
  }
}
</script>
