<template>
  <div style="min-height: 300px;">
    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <canvas id="usage-chart" width="400" height="100"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    chartData: {
      type: 'bar',
      data: {
        labels: [],
        datasets: {}
      },
      options: {
        responsive: true,
        lineTension: 0,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (Number.isSafeInteger(value)) {
                  return value
                }

                return ''
              }
            }
          }]
        }
      }
    },
    isLoading: true,
    hasErrors: false,
    errorMessage: ''
  }),
  methods: {
    setData: function (data) {
      this.chartData.data.datasets = data.history.dataSets
      this.chartData.data.labels = data.history.labels.map(this.$global.toLocaleDate)
      this.createChart()
      this.isLoading = false
    },
    setError: function (message) {
      this.errorMessage = message
      this.hasErrors = true
    },
    createChart () {
      const ctx = document.getElementById('usage-chart')
      return new Chart(ctx, {
        type: this.chartData.type,
        data: this.chartData.data,
        options: this.chartData.options
      })
    }
  },
  components: {
    spinner
  }
}
</script>
