<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setting up Device Backend using Amazon Web Services (AWS) cloud
    </h2>
    <h5 class="with-actions">Creating the CloudFormation stack</h5>
    <div class="help-section">
      <p>
        Percepio provides deploy scripts for running on Linux or Windows, along with a configuration file with all settings needed
        to have the Device Backend connect to the DevAlert Service. Running the script without arguments will show information about
        arguments that must be provided, and arguments that can be overridden (like which AWS profile or AWS region to use).
      </p>
      <p>
        <b>Note!</b> The deploy script must run with a profile linked to an AWS IAM User that has Admin rights,
        or similar policies allowing creating/updating of different resources including IAM roles and policies.<br/>
        See the <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id.html">AWS IAM documentation</a> for help on setting up IAM users.
      </p>
      <p>
        The scripts rely on AWS Command Line Interface (AWS CLI) for setting up the resources, see the
        <a href="https://aws.amazon.com/cli/">AWS CLI documentation</a> for help on setup and usage.
      </p>
      <p>
        The script also uses <b>jq</b> tool for handling inputs and outputs, see the
        <a href="https://stedolan.github.io/jq/download/">jq download page</a> for help on setup and usage.
      </p>
      <p>
        First time the deploy script is used, a stack <b>create</b> must be performed. This requires stack name, template file,
        template settings, domain, and an unique S3 bucket name.
        There are also optional parameters for profile and region.
      </p>
      <p>
        If an update is needed, a stack <b>update</b> must be performed. This requires stack name, template file, and
        template settings.
        There are also optional parameters for profile and region.
      </p>
      <p>
        After the CloudFormation stack is created, IAM users must be created for each developer needing access to the DevAlert
        traces stored in your AWS account (in the S3 bucket created by the stack). Use existing developer accounts,
        or create new ones, and attach the <b><i>DevAlert_Bucket_Policy</i></b> policy, which will allow these users to read the created S3 bucket.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
