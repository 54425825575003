<template>
  <div>
    <span v-if="hasErrors" class="alert alert-danger" role="alert">
      <h5 style="text-align: center;" >Alert Usage error</h5>
      {{ errorMessage }}
    </span>

    <div class="alert alert-danger" v-if="!isLoading && !hasErrors && showUsageHigh">
      <router-link :to='{ name: "Usage"}'>
        <b>Alert Usage is very high, click here to see more information</b>
      </router-link>
    </div>

    <div class="alert alert-warning" v-if="!isLoading && !hasErrors && showUsageMedium">
      <router-link :to='{ name: "Usage"}'>
        <b>Alert Usage is high, click here to see more information</b>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    isLoading: true,
    hasErrors: false,
    showUsageHigh: false,
    showUsageMedium: false,
    errorMessage: '',
    usageValue: 5,
    quotaValue: 100,
    progressValue: 5,
    usageDescription: 'Current Alert Usage, click for details'
  }),
  mounted () {
    if (this.loadPage) {
      this.getUsage()
    }
  },
  methods: {
    getUsage: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/usage')
        .then(response => {
          if (response.data.code === 200) {
            this.quotaValue = response.data.currentUsage.quota
            this.usageValue = response.data.currentUsage.used
            this.progressValue = 100 * this.usageValue / this.quotaValue

            if (this.progressValue > 85) {
              this.showUsageHigh = true
              this.showUsageMedium = false
            } else if (this.progressValue > 70) {
              this.showUsageHigh = false
              this.showUsageMedium = true
            }

            const timeBase = window._.camelCase(response.data.currentUsage.timeBase)
            this.usageDescription = 'Currently used ' + this.usageValue + ' of ' + this.quotaValue + ' alerts (per ' + timeBase + ')'

            this.isLoading = false
          } else {
            window.console.log(response.data.errors[0])

            this.errorMessage = 'Failed to get usage: ' + response.data.errors[0]
            this.hasErrors = true
            this.isLoading = false
          }
        }).catch(error => {
          window.console.error(error.message)

          this.errorMessage = 'Failed to get usage: ' + error.message
          this.hasErrors = true
          this.isLoading = false
        })
    }
  },
  computed: {
    loadPage: function () {
      return this.isAuthenticated && (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  }
}
</script>

<style>
.progress-outer {
  width: 100%;
  height: 1rem;
  margin-top: 1.4em;
  margin-bottom: 1.1em;
}
.progress-inner {
  width: 80%;
  height: 1rem;
  margin: 0 auto;
}
</style>
