<template>

  <v-footer app order="-1" v-if="isDemo && showFooter"
    class="expire-footer page-footer text-center"
  >
    <p style="margin: auto;">
      {{expireText}}
    </p>
    <a class="btn btn-sm btn-outline-primary" @click="hideFooter()" style="margin-right: 0;">
      X
    </a>
  </v-footer>

</template>

<script>
import { VFooter } from 'vuetify/components'

export default {
  mounted () {
    this.checkDemo()
  },
  computed: {
    isDemo: function () {
      return (this.$store.state.demo.expireTime > 0)
    },
    expireText: function () {
      if (this.$store.getters.expireDays > 1) {
        return 'Expires in ' + this.$store.getters.expireDays + ' days'
      }
      if (this.$store.getters.expireDays === 1) {
        return 'Expires in 1 day'
      }
      if (this.$store.getters.expireDays < 0) {
        return 'Has expired'
      }
      return 'Expires today'
    },
    showFooter: function () {
      return this.$store.state.demo.showExpireTime
    }
  },
  methods: {
    checkDemo: function () {
      this.$store.dispatch('updateDemoExpireTime')
    },
    hideFooter: function () {
      this.$store.commit('setShowExpireTime', false)
    }
  },
  components: {
    VFooter
  }
}
</script>
<style>
.expire-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0062cc;
  color: white;
  display:flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
}
</style>
