<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Getting Started Guide for SEGGER J-Link.
    </h2>
    <div class="help-section">
      <p>
        For targets without direct internet access, DevAlert includes a command line tool <b>devalertjlink</b>
        which can be used to connect the host to the target using a SEGGER J-Link debug probe and the SEGGER RTT
        protocol to retreive alert data. The alerts can then be uploaded to the cloud in a separate step.
      </p>
      <p>
        For Amazon Web Services (AWS), DevAlert includes another command line tool <b>devalerts3</b>
        that uploads the alerts to the AWS cloud, giving the same result as if using MQTT and
        AWS IoT Rule handler.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
