<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Cortex-M Hard Fault handler
    </h2>
    <div class="help-section">
      <p>
        Targets can detect and handle hard faults. This example is for Cortex-M based targets, but should be possible to adapt for other similar targets.<br/>
      </p>
    </div>
    <h4>
      Registering fault handlers in startup
    </h4>
    <div class="help-section">
      <p>
        In the project there should be a startup_&lt;<i>device</i>&gt;.s file which contains assembly code for setting up the target correctly.
        This file has a section for fault handlers that can probably already be used if they are defined. Find the section similar to this
        to know which functions are used. Some systems set up separate handlers for different faults,
        while others use one fault handler for all faults.
      </p>
      <pre><code>
  __vector_table

          ...

          DCD     HardFault_Handler
          DCD     MemManage_Handler
          DCD     BusFault_Handler
          DCD     UsageFault_Handler
      </code></pre>
      <p>
        The defined function(s) can then be used to handle each of the different faults.
      </p>
    </div>
    <h4>
      Redirecting fault handlers.
    </h4>
    <div class="help-section">
      <p>
        Declare your fault handler, preferrably in a header file.
      </p>
      <pre><code>
  /* Fault handler with DevAlert implementation */
  void UsageFault_Handler2(sContextStateFrame *frame);
      </code></pre>
      <p>
        Create an assembly macro to correctly redirect to your fault handler.
      </p>
      <pre><code>
  #define FAULT_HANDLING_ASM(_x)       \
    __asm volatile(                    \
        "tst lr, #4 \n"                \
        "ite eq \n"                    \
        "mrseq r0, msp \n"             \
        "mrsne r0, psp \n"             \
        "b UsageFault_Handler2 \n"     \
      )
      </code></pre>
      <p>
        Insert your assembly macro in the fault handler function(s).
      </p>
      <pre><code>
  void UsageFault_Handler(void) {
    FAULT_HANDLING_ASM();
  }

  void HardFault_Handler(void) {
    FAULT_HANDLING_ASM();
  }

  void MemManage_Handler(void) {
    FAULT_HANDLING_ASM();
  }

  void BusFault_Handler(void) {
    FAULT_HANDLING_ASM();
  }
      </code></pre>
    </div>
    <h4>
      Function handling the alert
    </h4>
    <div class="help-section">
      <!--p>
        This code is included in the <router-link :to='{ name: "DfmFiles"}'>DFM Recorder Files</router-link>
      </p-->
      <pre><code>
  /* Fault handler with DevAlert implementation */
  void UsageFault_Handler2(sContextStateFrame *frame)
  {
    uint32_t _CFSR;
    uint16_t _UFSR;
    uint16_t _BFSR;

    /* If the system should recover from the error or restart, 0 for restart. */
    uint8_t recover_error = 0;
    uint8_t do_not_send_to_cloud = 0;

    /* Start with generic hard fault, override if more detailed flag is found */
    uint32_t alertType = DMF_ALERT_HARD_FAULT;
    char * description = NULL;

    /* The address to the CFSR register where all faults are stored. */
    volatile uint32_t *cfsr = (volatile uint32_t *)0xE000ED28;

    /* Load all the different parts of the CFSR register. */
    _CFSR = SCB->CFSR;
    _UFSR = (_CFSR >> 16) &amp; 0xffff;
    _BFSR = (_CFSR >> 8);

    /* print the values of PC and registers to trace.
    snprintf(fmtbuf, sizeof(fmtbuf), "Value of PC: 0x%08X\r\n",
        frame->pc);

    vTracePrintF(devalert_user_event_channel, "%s", fmtbuf);
    snprintf(fmtbuf, sizeof(fmtbuf),
        "Value of R0: 0x%08X, R1: 0x%08X, R2: 0x%08X, R3: 0x%08X\r\n",
        frame->r0, frame->r1, frame->r2, frame->r3);

    vTracePrintF(devalert_user_event_channel, "%s", fmtbuf);

    /* Stops the recording and checks the error type and adds right symptoms. */
    if(_UFSR != 0)
    {
      /* A divide by zero instruction was executed. This fault must be enabled to trigger. */
      if (_UFSR &amp; UFSR_DIVBYZERO_Msk)
      {
        alertType = DMF_ALERT_UFSR_DIVBYZERO;
        description = "Division by Zero fault";
      }

      /* Indicates that an unaligned access operation occured. */
      if (_UFSR &amp; UFSR_UNALIGNED_Msk)
      {
        alertType = DMF_ALERT_UFSR_UNALIGNED;
        description = "Unaligned access fault";
      }

      /* Coprocessor instruction issued with no coprocessor present or enabled. */
      if(_UFSR &amp; UFSR_NOCP_Msk)
      {
        alertType = DMF_ALERT_UFSR_NOCP;
        description = "Coprocessor instruction issued with no coprocessor present or enabled";
      }

      /* Integrity check failure on EXC_RETURN from exception. */
      if(_UFSR &amp; UFSR_INVPC_Msk)
      {
        alertType = DMF_ALERT_UFSR_INVPC;
        description = "Integrity check failure on EXC_RETURN from exception";
      }

      /* Tried to execute instruction with an invalid EPSR value. */
      if(_UFSR &amp; UFSR_INVSTATE_Msk)
      {
        alertType = DMF_ALERT_UFSR_INVSTATE;
        description = "Tried to execute instruction with an invalid EPSR value";
      }

      /* Tried to execute undefined instruction, may be caused by a corrupt stack. */
      if (_UFSR &amp; UFSR_UNDEFINSTR_Msk)
      {
        alertType = DMF_ALERT_UFSR_UNDEFINSTR;
        description = "Tried to execute undefined instruction, may be caused by a corrupt stack";
      }
    }

    if(_BFSR != 0)
    {
      /* The BFAR hold the address which triggered the fault. */
      if(_BFSR &amp; BFSR_BFAR_Msk)
      {
        alertType = DFM_ALERT_BFSR_BFAR;
        snprintf(fmtbuf, sizeof(fmtbuf), "BFAR bit set, fault occured at address: 0x%08X", (uint)SCB->BFAR);
        description = fmtbuf;
        recover_error = 1;
      }

      /* Error on the data bus. If this error occurs the hardware wasn't able to determine the exact location of the fault. */
      if(_BFSR &amp; BFSR_IMPRECISERR_Msk)
      {
        recover_error = 1;

        /* Don't send the trace to the cloud since this fault is triggered by the ASSERT demo. */
        do_not_send_to_cloud = 1;
      }
    }

    DfmAlertHandle_t xAlertHandle;
    /* Do not try to send the alert since sending is not possible
       from hard fault handler, will store the alert instead. */
    xDfmSessionSetCloudStrategy(DFM_CLOUD_STRATEGY_OFFLINE);

    /* Create the alert, with details, an alert handle is returned */
    if (xDfmAlertBegin(alertType, description, &xAlertHandle) == DFM_SUCCESS)
    {
      /* Add alert symptoms */
      xDfmAlertAddSymptom(xAlertHandle, DFM_SYMPTOM_ARM_SCB_FCSR, SCB->CFSR);
      xDfmAlertAddSymptom(xAlertHandle, DFM_SYMPTOM_PC, frame->pc);

      /* Disable tracing */
      xTraceDisable();

      /* Add trace payload */
      prvAddTracePayload(xAlertHandle);

      /* Store the alert */
      xDfmAlertEnd(xAlertHandle);
    }

    NVIC_SystemReset();
  }

  static void prvAddTracePayload(DfmAlertHandle_t alertHandle)
  {
      char* szName;
      void* pvBuffer = (void*)0;
      uint32_t ulBufferSize = 0;
      if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_STREAMING)
      {
          szName = "dfm_trace.psfs";
      }
      else if (TRC_CFG_RECORDER_MODE == TRC_RECORDER_MODE_SNAPSHOT)
      {
          szName = "dfm_trace.bin";
      }

      xTraceGetEventBuffer(&pvBuffer, &ulBufferSize);
      xDfmAlertAddPayload(alertHandle, pvBuffer, ulBufferSize, szName);
  }
    </code></pre>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
        -
        <router-link :to='{ name: "DfmExamples"}'>Back to Examples</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
