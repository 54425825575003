<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Cloud AWS MQTT
    </h2>
    <div class="help-section">
      <p>
        Working with AWS, the recommended way is to use AWS IoT Core for devices, and MQTT for transfering data from
        the target to the cloud. Read the documentation for the device provider and AWS to get the project up and running.
      </p>
      <p>
        <ul>
          <li><a href="https://aws.amazon.com/getting-started/">Getting Started with AWS</a></li>
          <li><a href="https://docs.aws.amazon.com/iot/index.html">AWS IoT Core Documentation</a></li>
        </ul>
      </p>
      <p>
        Here is a short summary of the steps needed to use AWS IoT with your device:
        <ol>
          <li>Log in to AWS Console and make sure to select the correct <b>region</b></li>
          <li>Open <i>IoT Core</i> service and select <i>Manage</i> followed by <i>Types</i></li>
          <li>If no suitable thing type is already defined, <i>Create</i> one now</li>
          <li>Select <i>Secure</i> followed by <i>Policies</i></li>
          <li id="policyListItem">
            If no suitable policy is already defined, <i>Create</i> one now. See
            <a href="https://docs.aws.amazon.com/iot/latest/developerguide/example-iot-policies.html">AWS Documentation</a>
            for details and examples.
          </li>
          <li>Select <i>Manage</i> followed by <i>Things</i> and click <i>Create</i>, then Create a single thing</li>
          <li>Enter the needed information; name, type, then go to Next</li>
          <li>
            Select <i>Create certificate</i>, or provide your own certificate (<a href="https://docs.aws.amazon.com/iot/latest/developerguide/device-certs-your-own.html">more details on how</a>)<br/>
            <b>Note!</b> Remember to <i>Activate</i> the certificate, if needed, and to download the files; otherwise you will need to go back and repeat this step later
          </li>
          <li>Select <i>Add policy</i> and select the policy from step {{ policyStep }}, followed by <i>Register Thing</i></li>
          <li>Update your device with the thing name, and the certificate and keys you downloaded</li>
        </ol>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false,
    policyStep: 0
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    this.policyStep = $('#policyListItem').index() + 1
  }
}
</script>
