<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Device setup using FreeRTOS
    </h2>
    <div class="help-section">
      <p>
        Amazon Web Services (AWS) cloud customers can find several getting started guides for specific targets in the
        <a href="https://docs.aws.amazon.com/freertos/latest/userguide/getting-started-guides.html">Board-specific getting started guides for AWS</a>.
        Even when referring to a slightly different board, these guides can make the setup and integration a lot faster.
      </p>
      <p>
        Most popular IDEs today also feature good examples and templates for getting started with FreeRTOS.
        If the device should connect to a Cloud provider directly, the example/template should include internet connection,
        like Wi-Fi, and also MQTT setup if that is required.
      </p>
      <p>
        If you are not using a cloud provider directly with the device, and also not using an IDE, refer to the official
        <a href="https://freertos.org/">FreeRTOS website</a> to get help setting up the project.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
