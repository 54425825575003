<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div v-if="!isLoading">
      <router-link class='btn btn-secondary' :to='{ name: "Symptoms"}'>Back</router-link>

      <h2 class="with-actions">
        Edit {{ originalDescription }}
      </h2>

      <spinner v-if="isLoading"></spinner>

      <form v-on:submit.prevent="submitForm">
        <div class="float-right status-bar">
          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-success" :disabled="collection.published && !collection.retired" v-on:click="updateStatus('published')">
              Publish
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-info" :disabled="collection.retired" v-on:click="updateStatus('retired')">
              Retire
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button class="btn btn-secondary"  type="submit" :disabled="collection.published">
              Save Updates
            </button>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Definition</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.definition" pattern="[A-Z][A-Z0-9_]+" :disabled="collection.published">
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Description</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description" :disabled="collection.published">
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Data type</label>
          <select class="form-control" v-model="collection.dataType" :disabled="collection.published">
            <option value="dec">DEC</option>
            <option value="int">INT</option>
            <option value="hex">HEX</option>
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Significant</label>
          <select class="form-control" v-model="collection.significant" :disabled="collection.published">
            <option :value="true">YES</option>
            <option :value="false">NO</option>
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Updated</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.updated" disabled>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Published</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.published" disabled>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Retired</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.retired" disabled>
        </div>

        <div class="form-group">
          <button class="btn btn-lg btn-block btn-primary" type="submit" v-if="!collection.published">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      key: null,
      definition: null,
      description: null,
      significant: null,
      retired: null,
      state: false,
      dataType: null,
      published: null
    },
    originalDescription: '',
    isLoading: true
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    this.getEditData()
  },
  methods: {
    getEditData: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/symptoms/' + this.$route.params.id)
        .then(response => {
          if (!response.data.symptomDescriptions.length) {
            this.$router.push('/symptoms')
          }

          this.collection = response.data.symptomDescriptions[0]
          this.originalDescription = this.collection.description
          this.isLoading = false
        })
    },
    submitForm: function () {
      this.submitData({
        description: this.collection.description,
        definition: this.collection.definition,
        dataType: this.collection.dataType,
        significant: this.collection.significant
      })
    },
    updateStatus: function (field) {
      this.collection.state = field

      this.submitData({
        state: this.collection.state
      })
    },
    submitData: function (collection) {
      window.axios({
        method: 'PATCH',
        url: this.$store.state.auth.serviceEndpoint + '/symptoms/' + this.$route.params.id,
        data: collection
      }).then(response => {
        this.collection = response.data.symptomDescriptions[0]
        this.originalDescription = this.collection.description
      })
    }
  },
  components: {
    spinner
  }
}
</script>
