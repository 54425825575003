<template>
  <div>
    <router-link class='btn btn-secondary' :to='{ name: "Products"}'>Back</router-link>

    <h2 class="with-actions">
      New Product
    </h2>

    <form v-on:submit.prevent="submitFields">
      <div class="form-group">
        <label for="exampleFormControlInput1">Identifier</label>
        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.identifier" pattern="[A-Z][A-Z0-9_]+" required>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Description</label>
        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description" required>
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Is Default</label>
        <select class="form-control" v-model="collection.isDefault">
          <option :value="true">YES</option>
          <option :value="false">NO</option>
        </select>
      </div>

      <div class="form-group">
        <button class="btn btn-lg btn-block btn-primary" type="submit">Submit</button>
      </div>
    </form>
    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      key: null,
      identifier: '',
      description: '',
      isDefault: false
    },
    isLoading: false,
    hasErrors: false,
    errorMessage: ''
  }),
  mounted () {
  },
  methods: {
    submitFields: function () {
      this.isLoading = true
      this.hasErrors = false
      window.axios({
        method: 'POST',
        url: this.$store.state.auth.serviceEndpoint + '/products',
        data: {
          description: this.collection.description,
          identifier: this.collection.identifier,
          isDefault: this.collection.isDefault
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$router.push('/products')
        }

        this.collection = response.data.productDescriptions[0]
        this.isLoading = false
      }).catch(error => {
        console.log(error.message)
        this.isLoading = false
        this.hasErrors = true
        this.errorMessage = 'Failed to create product: ' + error.message
      })
    }
  },
  components: {
    spinner
  }
}
</script>
