<template>
  <div>
    <h2 class="list-page width-actions" >
      Alert Types
      <router-link class='btn btn-primary float-right' :to='{ name: "AlertTypesNew"}' v-if="isDeveloper">Add new Alert Type</router-link>
    </h2>

    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div class="table-responsive" v-if="!isLoading && !hasErrors">
      <div>
        <form>
        <table style="width:100%; border: 0px">
          <tr>
            <td style="width:*">
              <input type="text" class="form-control" id="textFilter42" placeholder="Filter for text" v-model="searchFields.textFilter">
            </td>
            <td style="width:110px; text-align: center!important;">
              <button type="submit" class="btn btn-info" v-on:click.prevent="performFilter">Apply</button>
            </td>
            <td style="width:110px; text-align: center!important;">
              <button type="submit" class="btn btn-secondary" v-on:click.prevent="clearFilter">Clear</button>
            </td>
          </tr>
        </table>
        </form>
      </div>

      <table class="table table-striped table-sm">
          <thead>
              <tr>
                  <th class="sort-by" @click="sortBy('alertType', $event)">
                    Type
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th class="sort-by" @click="sortBy('definition', $event)">
                    Definition
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th class="sort-by" @click="sortBy('description', $event)">
                    Description
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th class="sort-by" @click="sortBy('published', $event)">
                    State
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th>Updated</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>

            <tr
              v-for="(dataRow, index) in collection"
              :key="index"
            >
              <td>{{ dataRow.alertType}}</td>
              <td>{{ dataRow.definition}}</td>
              <td>{{ dataRow.description}}</td>
              <td>{{ dataRow.retired ? 'Retired' : (dataRow.published ? 'Published' : '') }} </td>
              <td>{{ $global.toLocalTime(dataRow.updated) }}</td>
              <td>
                <span style='margin-right:1em;'><router-link class='btn btn-secondary' :to='{ name: "Alerts", query: { alertType: dataRow.alertType }}'>Alerts</router-link></span>
                <span><router-link class='btn btn-secondary' :to='{ name: "AlertTypesEdit", params: { id: dataRow.alertType }}' v-if="isDeveloper">Edit</router-link></span>
              </td>
            </tr>
          </tbody>
      </table>
      <pagination
        :total="paginate.total"
        :page-size="paginate.pageSize"
        :callback="getTableData"
        :currentPage="paginate.currentPage"
        nav-class="padding-10"
        ul-class="bg-color-red"
        li-class="txt-color-blue">
      </pagination>

    </div>
  </div>
</template>

<script>
import pagination from '../Utils/Paginate'
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {},
    searchFields: {
      sort: {
        sortName: 'alertType',
        sortOrder: 'DESC'
      },
      textFilter: ''
    },
    paginate: {
      total: 100,
      pageSize: 50,
      currentPage: 1
    },
    isDeveloper: false,
    isLoading: true,
    hasErrors: false,
    errorMessage: ''
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    if (this.isAuthenticated) {
      this.getTableData(1)
    }
  },
  methods: {
    getTableData: function (page) {
      if (typeof page === 'undefined') {
        page = 1
      }

      this.isLoading = true

      let queryUri =
        '?page=' + page +
        '&sortName=' +
        this.searchFields.sort.sortName +
        '&sortOrder=' +
        this.searchFields.sort.sortOrder

      if (!(/^\s*$/).test(this.searchFields.textFilter)) {
        queryUri += '&textFilter=' + this.searchFields.textFilter
      }

      window.axios.get(this.$store.state.auth.serviceEndpoint + '/alert_types' + queryUri)
        .then(response => {
          this.collection = response.data.alertDescriptions
          this.paginate = response.data.pagination
          this.isLoading = false
        }).catch(error => {
          console.log(error.message)
          this.isLoading = false
          this.hasErrors = true
          this.errorMessage = 'Failed to get AlertTypes: ' + error.message
        })
    },
    sortBy: function (field, event) {
      this.loading = true
      // Reset all to bottom
      if (field !== this.searchFields.sort.sortName) {
        $('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
        this.searchFields.sort.sortOrder = 'DESC'
      }
      this.searchFields.sort.sortName = field
      if (this.searchFields.sort.sortOrder === 'ASC') {
        this.searchFields.sort.sortOrder = 'DESC'
        $(event.currentTarget)
          .find('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
      } else {
        this.searchFields.sort.sortOrder = 'ASC'
        $(event.currentTarget)
          .find('.oi.oi-caret-bottom')
          .removeClass('.oi.oi-caret-bottom')
          .addClass('oi-caret-top')
      }
      return this.getTableData(this.currentPage)
    },
    clearFilter: function () {
      this.searchFields.textFilter = ''
      return this.getTableData(this.currentPage)
    },
    performFilter: function () {
      return this.getTableData(this.currentPage)
    }
  },
  components: {
    spinner,
    pagination
  }
}
</script>
