<template>
  <div>
    <router-link class='btn btn-secondary' :to='{ name: "Symptoms"}'>Back</router-link>

    <h2 class="with-actions">
      New Symptom
    </h2>

    <form v-on:submit.prevent="submitFields">
      <div class="form-group">
        <label for="exampleFormControlInput1">Definition</label>
        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.definition" pattern="[A-Z][A-Z0-9_]+">
      </div>

      <div class="form-group">
        <label for="exampleFormControlInput1">Description</label>
        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description">
      </div>

      <div class="form-group">
        <label for="exampleFormControlInput1">Data type</label>
        <select class="form-control" v-model="collection.dataType">
          <option value="dec">Decimal</option>
          <option value="int">Integer</option>
          <option value="hex">Hexadecimal</option>
        </select>
      </div>

      <div class="form-group">
        <label for="exampleFormControlInput1">Significant</label>
        <select class="form-control" v-model="collection.significant">
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </div>
      <div class="form-group">
        <button class="btn btn-lg btn-block btn-primary" type="submit">Submit</button>
      </div>
    </form>
    <spinner v-if="isLoading"></spinner>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      definition: '',
      description: '',
      dataType: 'dec',
      significant: false
    },
    isLoading: false,
    hasErrors: false,
    errorMessage: ''
  }),
  mounted () {
  },
  methods: {
    submitFields: function () {
      this.isLoading = true
      this.hasErrors = false
      window.axios({
        method: 'POST',
        url: this.$store.state.auth.serviceEndpoint + '/symptoms',
        data: {
          severity: this.collection.severity,
          description: this.collection.description,
          definition: this.collection.definition,
          dataType: this.collection.dataType,
          significant: this.collection.significant
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$router.push('/symptoms')
        }

        this.collection = response.data.symptomDescriptions[0]
        this.isLoading = false
      }).catch(error => {
        console.log(error.message)
        this.isLoading = false
        this.hasErrors = true
        this.errorMessage = 'Failed to create symptom: ' + error.message
      })
    }
  },
  components: {
    spinner
  }
}
</script>
