<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Percepio DevAlert Dispatcher Examples
    </h2>
    <div class="help-section">
      <p>
        This section contains some examples of how to set up the Dispatcher to launch the correct application or script.
      </p>
      <p>
        If you have a different scenario that you are having issues with, reach out to us at <a href="mailto:support@percepio.com">support@percepio.com</a>.
      </p>
    </div>

    <h3 class="with-actions">
      Tracealyzer&#174; mapping
    </h3>
    <div class="help-section">
      <p>
        When setting up a mapping for Percepio Tracealyzer, the easiest way is to start with one of the <i>Template Mappings</i>;
        simply click the <i>Template Mapping</i> button and pick one of the Tracealyzer mappings.<br/>
        The mapping should be using Extension, change it to the file extension you want to handle.<br/>
        By default the path to Tracealyzer executable should be found, if not use the <i>Browse...</i> button to find it.<br/>
        On Windows it is by default installed in your Program Files\Perceio\Tracealyzer, and you must select the <samp>Tracealyzer.exe</samp> file as the executable.<br/>
        On Linux you have selected your install directory yourself, often in your home directory, and you must select <samp>launch-tz.sh</samp> as the executable.<br/>
        For Windows you will not need Create Window or Use Shell Executable, but for Linux you can use Create Window if you are having problems launching,
        and you should Use Shell Executable.<br/>
        The parameter must be <samp>/open ${file}</samp>.
      </p>
    </div>

    <h3 class="with-actions">
      CrashDebug mapping
    </h3>
    <div class="help-section">
      <p>
        To be able to open Crash dumps using CrashDebug a mapping of the Crash payload is needed. CrashDebug binary can be downloaded from its github page.
        To launch CrashDebug correct, scripts are included in Dispatcher that could be used in the mapping,
        <samp>template/crash_debug_log.bat</samp> for Windows and <samp>template/crash_debug_log.sh</samp> for Linux.
      </p>
      <p>
        Copy the files to any local directory (do <i>not</i> change them under the template folder directly).
        The script assume that CrashDebug binary is located in the same directory as the script.
      </p>
      <p>
        <b>Note!</b> The script will not work as it is, but some modifications (like paths to GDB executable) must be set up.
      </p>
      <p>
        Add a File mapping, preferrably using the <i>Template Mapping</i> for CrashDebug.<br/>
      </p>
      <p>
        The script support two modes.<br/>
        Normal mode, template called &quot;log&quot;, being called with just the elf file and the dump file, will output information
        to a log file and then open it in your default text editor.<br/>
        Active mode, template called &quot;log&quot;, will also include a third parameter <samp>--gdb</samp> which will keep the GDB session open,
        allowing you to actively debug it from your IDE.
      </p>
    </div>

    <h3 class="with-actions">
      Script to fetch ELF file
    </h3>
    <div class="help-section">
      <p>
        To pre-fetch an ELF file from your repo, there are example files included with the Dispatcher under the <i>template</i> directory,<br/>
        pre_script.bat for Windows and pre_script.sh for Linux.<br/>
        Copy the files to any local directory (do <i>not</i> change them under the template folder directly)
        and edit the script as needed in them to match your system.
      </p>
      <p>
        <b>Note!</b> This script is not expected to work for your system as it is, but must be modified.
      </p>
      <p>
        The template version of the script uses curl to fetch the file, but initially only output the command to ensure the script will not fail
        to execute if the system doesn't have curl installed.<br/>
        The script also require <samp>${product}</samp> to have the format <samp>&quot;PRODUCT-MODULE&quot;</samp> and the <samp>${revision}</samp> the format <samp>&quot;REVISION-RELEASE&quot;</samp>,
        which will be split at the <samp>&quot;-&quot;</samp> sign into the different parts before calling curl.<br/>
        There is also no <i>post processing</i> in the script, i.e. unpacking of the fetched file(s) if needed, or moving them to the right directory,
        because this is system dependent.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
        -
        <router-link :to='{ name: "Dispatcher"}'>Back to Dispatcher</router-link>
      </div>
    </div>
  </div>
</template>
