<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div v-if="!isLoading">
      <router-link class='btn btn-secondary' :to='{ name: "Notifications"}'>Back</router-link>

      <h2 class="with-actions">
        Notifications edit: {{ collection.name }} (state: {{ collection.state }})
      </h2>

      <spinner v-if="isLoading"></spinner>

      <form v-on:submit.prevent="submitForm">
        <div class="float-right status-bar">
          <div class="float-left" v-if="collection.state === 'Active'">
            <button type="button" class="btn btn-block btn-success" :disabled="collection.state !== 'Active'" v-on:click="updateStatus('Inactive')">
              Deactivate
            </button>
          </div>

          <div class="float-left" v-else-if="collection.state === 'Pending'">
            <b>Pending</b><br />
          </div>

          <div class="float-left" v-else>
            <button type="button" class="btn btn-block btn-info" :disabled="collection.state !== 'Inactive'" v-on:click="updateStatus('Active')">
              Activate
            </button>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Name</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.name" :disabled="true">
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      name: null,
      state: null
    },
    isLoading: true
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    this.getEditData()
  },
  methods: {
    getEditData: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/notifications/' + this.$route.params.id)
        .then(response => {
          if (!response.data.topics.length) {
            this.$router.push('/notifications')
          }

          this.collection = response.data.topics[0]
          this.isLoading = false
        })
    },
    submitForm: function () {
      this.submitData({
        name: this.collection.name,
        state: this.collection.state
      })
    },
    updateStatus: function (field) {
      this.collection.state = field

      this.submitData({
        name: this.$route.params.id,
        state: this.collection.state
      })
    },
    submitData: function (collection) {
      window.axios({
        method: 'PATCH',
        url: this.$store.state.auth.serviceEndpoint + '/notifications/' + collection.name + '?state=' + collection.state
      }).then(response => {
        this.collection = response.data.topics[0]
      })
    }
  },
  components: {
    spinner
  }
}
</script>
