<template>
  <div>
    <h2 class="list-page width-actions">
      Code Export
    </h2>

    <spinner v-if="isLoading"/>

    <div v-if="!isLoading">
      <p>
        This function exports the Alert and Symptom definitions as a header file for the DFM library. Make sure to regenerate and replace the original dfmCodes.h (/dfm/includes) with the new file if new Alerts or Symptoms are added.
      </p>
    </div>

    <div class="generate-container" v-if="!isLoading">
      <form v-on:submit.prevent="submitForm">
        <input type="checkbox" id="nonPub" v-model="nonPublished" />
        <label for="nonPub">&nbsp;Include Non-Published</label>
        <br/>
        <input type="checkbox" id="retired" v-model="retired" />
        <label for="retired">&nbsp;Include Retired</label>
      </form>
    </div>

    <div class="generate-container" v-if="!isLoading">
      <button type="button" class="btn btn-primary" v-on:click="generateHeaders(nonPublished, retired)" :disabled="isWorking">
        Generate Header File
      </button>
    </div>

    <spinner v-if="isWorking"/>

    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div class="generate-container" v-if="hasFile">

      <h4 class="list-page width-actions" v-if="!isWorking">
        Preview
      </h4>

      <textarea v-if="!isWorking" class="textarea-codes" type="textarea" v-model="body" readOnly="true"/>
    </div>

    <div class="generate-container" v-if="hasFile && !isLoading">
      <button type="button" class="btn btn-info" v-on:click="saveHeader()" :disabled="!hasFile">
        Download File
      </button>

      <br/><br/>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    body: [''],
    nonPublished: true,
    retired: false,
    isLoading: false,
    isWorking: false,
    hasFile: false,
    urlUpload: false,
    hasErrors: false,
    errorMessage: ''
  }),
  methods: {
    generateHeaders: function (nonPublished, retired) {
      this.isWorking = true
      this.hasErrors = false

      const queryUri =
        '?includeNonPublished=' + nonPublished +
        '&includeRetired=' + retired +
        '&urlUpload=' + this.urlUpload

      window.axios.get(this.$store.state.auth.serviceEndpoint + '/header_codes' + queryUri)
        .then(response => {
          this.body = ['' + response.data.body]
          this.hasFile = true
          this.isWorking = false
        }).catch(error => {
          console.log(error.message)
          this.isWorking = false
          this.hasErrors = true
          this.errorMessage = 'Failed to get header_codes: ' + error.message
        })
    },
    saveHeader: function () {
      const blob = new Blob(this.body, { type: 'text/plain' })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'dfmCodes.h')
      } else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = 'dfmCodes.h'
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
        window.URL.revokeObjectURL(elem.href)
      }
    }
  },
  components: {
    spinner
  }
}
</script>
