<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list density="compact" nav>
      <v-list-item style="text-decoration: none;" v-if="hasDeveloperFeature" title="Welcome Developer!" link prepend-icon="mdi-flag"/>
      <v-list-item style="text-decoration: none;" v-if="!hasServiceUrl" title="Welcome" to="/welcome" prepend-icon="mdi-message-text-outline"/>
      <v-list-item style="text-decoration: none;" v-if="hasServiceUrl" title="Dashboard" to="/" prepend-icon="mdi-view-dashboard"/>
      <v-list-item style="text-decoration: none;" v-if="hasServiceUrl" title="Alerts" to="/alerts" prepend-icon="mdi-alert"/>

      <v-list-group v-if="hasServiceUrl">
        <template v-slot:activator="{ props }">
          <v-list-item style="text-decoration: none;" v-bind="props" prepend-icon="mdi-cog-outline">
            <v-list-item-title class="text-no-wrap">Configuration</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item style="text-decoration: none;" title="Products" to="/products" prepend-icon="mdi-package-variant-closed"/>
        <v-list-item style="text-decoration: none;" title="Devices" to="/devices" prepend-icon="mdi-water-boiler"/>
        <v-list-item style="text-decoration: none;" title="Alert Types" to="/alert-types" prepend-icon="mdi-radar"/>
        <v-list-item style="text-decoration: none;" title="Symptoms" to="/symptoms" prepend-icon="mdi-shape-outline"/>
        <v-list-item style="text-decoration: none;" title="Code Export" to="/headers" prepend-icon="mdi-export"/>
      </v-list-group>

      <v-list-group v-if="hasServiceUrl">
        <template v-slot:activator="{ props }">
          <v-list-item style="text-decoration: none;" v-bind="props" prepend-icon="mdi-book-open-blank-variant">
            <v-list-item-title class="text-no-wrap">Documentation</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item style="text-decoration: none;" title="Overview" to="/help"/>
        <v-list-item style="text-decoration: none;" title="Console Help" to="/console"/>

        <v-list-group value="getting-started">
          <template v-slot:activator="{ props }">
            <v-list-item style="text-decoration: none;" v-bind="props" title="Getting Started"/>
          </template>

          <v-list-item style="text-decoration: none;" title="Overview" to="/startup"/>
          <v-list-item style="text-decoration: none;" title="Percepio DevAlert Service" to="/startup/percepio"/>

          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item style="text-decoration: none;" v-bind="props" title="Setup Device Backend"/>
            </template>

            <v-list-item style="text-decoration: none;" title="Overview" to="/startup/customer"/>
            <v-list-item style="text-decoration: none;" v-if="showAws" title="AWS" to="/startup/customer/aws"/>
            <v-list-item style="text-decoration: none;" v-if="showAzure" title="Azure" to="/startup/customer/azure"/>
            <v-list-item style="text-decoration: none;" v-if="showLocal" title="Local Storage" to="/startup/customer/localstorage"/>
          </v-list-group>

          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item style="text-decoration: none;" v-bind="props" title="Setup Device"/>
            </template>

            <v-list-item style="text-decoration: none;" title="Overview" to="/startup/device"/>
            <v-list-item style="text-decoration: none;" title="Alert Key Uniqueness" to="/alert-keys"/>
            <v-list-item style="text-decoration: none;" v-if="showFreeRTOS" title="FreeRTOS" to="/startup/device/freertos"/>
            <v-list-item style="text-decoration: none;" v-if="showThreadX" title="Azure RTOS ThreadX" to="/startup/device/azure-rtos"/>
            <v-list-item style="text-decoration: none;" v-if="showBareMetal" title="Bare Metal" to="/startup/device/bare-metal"/>
          </v-list-group>

          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item style="text-decoration: none;" v-bind="props" title="Device Connection"/>
            </template>

            <v-list-item style="text-decoration: none;" title="Overview" to="/startup/cloud"/>
            <v-list-item style="text-decoration: none;" v-if="showAws" title="AWS with MQTT" to="/startup/cloud/aws-mqtt"/>
            <v-list-item style="text-decoration: none;" v-if="showAzure && hasDeveloperFeature" title="Azure with MQTT" to="/startup/cloud/azure-mqtt"/>
            <v-list-item style="text-decoration: none;" v-if="showLocal" title="Local, RTT" to="/startup/cloud/rtt"/>
          </v-list-group>

          <v-list-item style="text-decoration: none;" v-if="showFreeRTOS" title="Trace in FreeRTOS" to="/startup/trace/freertos"/>
          <v-list-item style="text-decoration: none;" v-if="showThreadX && hasDeveloperFeature" title="Trace in Azure RTOS ThreadX" to="/startup/trace/azure-rtos"/>
        </v-list-group>

        <v-list-group value="dfm">
          <template v-slot:activator="{ props }">
            <v-list-item style="text-decoration: none;" v-bind="props" title="Device Firmware Monitor (DFM)"/>
          </template>

          <v-list-item style="text-decoration: none;" title="Overview" to="/dfm"/>
          <v-list-item style="text-decoration: none;" v-if="showAws" title="AWS with MQTT" to="/dfm/aws-mqtt"/>
          <v-list-item style="text-decoration: none;" v-if="showAzure && hasDeveloperFeature" title="Azure with MQTT" to="/dfm/azure-mqtt"/>
          <v-list-item style="text-decoration: none;" v-if="showLocal" title="RTT" to="/dfm/rtt"/>
          <v-list-item style="text-decoration: none;" title="Download" to="/dfm/files"/>
          <v-list-group value="examples">
            <template v-slot:activator="{ props }">
              <v-list-item style="text-decoration: none;" v-bind="props" title="Examples"/>
            </template>

            <v-list-item style="text-decoration: none;" title="DFM Examples" to="/dfm/examples"/>
            <v-list-item style="text-decoration: none;" title="Crash Catcher Integration" to="/dfm/examples/crash-catcher"/>
            <v-list-item style="text-decoration: none;" title="Invalid state in Application" to="/dfm/examples/application-state"/>
            <v-list-item style="text-decoration: none;" v-if="showFreeRTOS" title="Failed malloc calls in FreeRTOS" to="/dfm/examples/free-rtos-malloc"/>
            <v-list-item style="text-decoration: none;" title="Cortex-M Hard Fault Handler" to="/dfm/examples/cortex-m-hard-fault"/>
          </v-list-group>
        </v-list-group>

        <v-list-group value="dispatcher">
          <template v-slot:activator="{ props }">
            <v-list-item style="text-decoration: none;" v-bind="props" title="DevAlert Dispatcher"/>
          </template>

          <v-list-item style="text-decoration: none;" title="Overview" to="/dispatcher"/>
          <v-list-item style="text-decoration: none;" title="Dispatcher Setup" to="/dispatcher/setup"/>
          <v-list-item style="text-decoration: none;" title="Dispatcher Examples" to="/dispatcher/examples"/>
        </v-list-group>

        <v-list-item style="text-decoration: none;" title="Percepio Tracealyzer&#174;" to="/tracealyzer"/>      </v-list-group>
    </v-list>
  </v-navigation-drawer>
  <v-btn v-if="!drawer" class="floating-menu-button" icon="mdi-menu" color="primary" @click.stop="drawer = !drawer"></v-btn>
</template>

<script>
import { VList, VListItem, VListItemTitle, VListGroup, VNavigationDrawer, VBtn } from 'vuetify/components'

export default {
  data: () => ({
    isDeveloper: false,
    showAws: true,
    showAzure: true,
    showLocal: true,
    showFreeRTOS: true,
    showThreadX: true,
    showBareMetal: true,
    drawer: null
  }),
  components: {
    VList,
    VListItem,
    VListItemTitle,
    VListGroup,
    VNavigationDrawer,
    VBtn
  },
  computed: {
    isDemo: function () {
      return (this.$store.state.demo.expireTime > 0)
    },
    filterTags: function () {
      return (this.$store.state.auth.filterTags)
    },
    hasServiceUrl: function () {
      return (this.$store.state.auth.serviceEndpoint.length > 1)
    },
    hasDeveloperFeature: function () {
      return this.$store.state.features.featuresLoaded && this.$store.state.features.features.includes('Development')
    }
  },
  mounted () {
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
    this.showAws = this.$store.state.auth.filterTags.includes('AWS')
    this.showAzure = this.$store.state.auth.filterTags.includes('Azure') && this.hasDeveloperFeature
    this.showLocal = this.$store.state.auth.filterTags.includes('Local')
    this.showFreeRTOS = this.$store.state.auth.filterTags.includes('FreeRTOS')
    this.showThreadX = this.$store.state.auth.filterTags.includes('ThreadX') && this.hasDeveloperFeature
    this.showBareMetal = this.$store.state.auth.filterTags.includes('BareMetal')
  }
}
</script>

<style>
.v-list-item-title {
  white-space: normal!important;
}
.v-list-group {
  --prepend-width: 4px!important;
}
.floating-menu-button{
  position:fixed;
  bottom:10px;
  left:10px;
}
</style>
