<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DFM Download
    </h2>

    <div v-if="isLoading" class="help-section">
      Fetching source link...
      <spinner></spinner>
    </div>

    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}<br/>
      Contact <a href="mailto:support@percepio.com">Percepio Support</a>
    </div>

    <div v-if="!hasErrors && !isLoading" class="help-section">
      All the files can be downloaded <a :href='sourceUrl'>from here</a>!
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    isLoading: true,
    hasErrors: false,
    sourceUrl: '#',
    errorMessage: '',
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')

    this.readConfig()
  },
  methods: {
    readConfig: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/config')
        .then(response => {
          this.sourceUrl = response.data.DFM_SOURCE
          this.isLoading = false

          if (this.sourceUrl === undefined || this.sourceUrl === '') {
            window.console.log('URL is bad')
            this.errorMessage = 'Could not retrieve source url'
            this.hasErrors = true
          } else {
            window.console.log('URL is good: ' + this.sourceUrl)
          }
        }).catch(error => {
          window.console.log(error.message)
          this.isLoading = false
          this.errorMessage = 'Failed to get issues: ' + error.message
          this.hasErrors = true
        })
    }
  },
  components: {
    spinner
  }
}
</script>
