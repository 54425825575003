<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      What is DevAlert?
    </h2>
    <div class="help-section">
      <p>
        DevAlert provides device-to-cloud issue reporting and tracking, with remote debugging support, intended for RTOS-based embedded software.
      </p>
      <p>
        This uses a <router-link :to='{ name: "Dfm"}'>device client library, DFM</router-link>, that allows for generating alerts from the device to the DevAlert cloud service.
        This is typically used when your device software detects something out of the ordinary, like an error, crash, or suspicious activity.
        Any kind of diagnostic data, such as logs, traces and crash dumps can be included with the alerts to simplify analysis and debugging.
      </p>
      <p>
        <img src="@/assets/images/da_overview.png" alt="overview" style="width:100%;height:100%;object-fit:cover;" />
      </p>
      <p>
        The DFM library doesn't connect directly to the DevAlert service from the device.
        Instead, it uploads the alert to your device backend, i.e. the cloud account or private server that your device software already connects to, using the existing connectivity.
        The <router-link :to='{ name: "GettingStarted"}'>device backend needs to be configured</router-link> to store the diagnostic data in a private storage and forward the alert metadata to the DevAlert service.
      </p>
      <p>
        The diagnostic data remains in the customers private domain, but can still be accessed by a single click in the DevAlert web interface.
        This provides download links that triggers the <router-link :to='{ name: "Dispatcher"}'>DevAlert Dispatcher</router-link>,
        a desktop tool that downloads the diagnostic data from your private storage and shows it in the right desktop tool.
      </p>
      <p>
        The <router-link :to='{ name: "Dashboard"}'>DevAlert Dashboard</router-link> shows an overview of the unique issues, based on the “fingerprint” in the alert metadata (see below).
        Email notifications can be provided on new issues, if configured under <router-link :to='{ name: "Notifications"}'>Notifications</router-link>.
        This way, you can react quickly on new issues and ensure nothing is missed, without getting spammed about every individual repetition.
      </p>
      <p>
        If needed, the full list of alerts can be found under <router-link :to='{ name: "Alerts"}'>Alerts</router-link>.
        This shows the input to the DevAlert service, before the classification into issues.
      </p>
    </div>

    <h3 class="with-actions">
      Alert Fingerprints and Issues
    </h3>
    <div class="help-section">
      <p>
        The DevAlert service aggregates the alerts into unique issues, which is key to effective monitoring at scale.
        Instead of reviewing potentially thousands of individual alerts, you get a more manageable Issue Overview in the <router-link :to='{ name: "Dashboard"}'>Dashboard</router-link>.
        An issue is a collection of alerts with the same fingerprint.
        The fingerprints is a part of the metadata and includes the <router-link :to='{ name: "AlertTypes"}'>Alert Type</router-link> and the <router-link :to='{ name: "Symptoms"}'>Symptoms</router-link>.
        You may define your own alert types and symptoms to match your needs.
        The definitions are <router-link :to='{ name: "Headers"}'>exported as C header files</router-link>, containing a list of Alert and Symptom IDs for your device software.
        These are included as parameters when creating alerts using the DFM library.
      </p>
      <p>
        <b>Note!</b> Do not edit the generated header file, as the target-side definitions must match the definitions in the DevAlert database.
      </p>
      <p>
        Alerts may also include <router-link :to='{ name: "Symptoms"}'><i>symptoms</i></router-link> to provide a more detailed fingerprint.
        The symptoms are defined by the integrator in a similar way as alert types and <router-link :to='{ name: "DfmExamplesApplictionState"}'>specified when creating an alert</router-link> using the DFM library.
        Suitable symptoms to include could be the program counter, stack pointer, running task and fault status registers.
      </p>
      <p>
        See <router-link :to='{ name: "AlertKeys"}'>Alert Key Uniqueness</router-link> page for details on how to maintain uniqueness within a large fleet of devices.
      </p>
    </div>

    <h3 class="with-actions">
      Uploads and Device Storage
    </h3>
    <div class="help-section">
      <p>
        The DevAlert client library only uploads data when requested, i.e., when an Alert is created, otherwise it is silent.
        For real-time notifications, the alerts can be uploaded to the existing cloud account of the device and automatically forwarded to the DevAlert service.
        The upload to the device backend is managed by the dfmCloud.c/.h in the DFM library.
        We provide <router-link :to='{ name: "StartupCloud"}'>example cloud ports for common cases</router-link>, but other integrations are possible.
      </p>
        It is also possible to store the data on the device, e.g. using flash memory, and upload it later when connectivity is available.
        The storage is device-specific and managed by dfmStorage.h. We provide an example for STM32 using the HAL API.
      <p>
      </p>
      <p v-if="showLocal">
        It is also possible to upload the alerts to a directly connected host computer using a debugger, <router-link :to='{ name: "StartupCloudRTT"}'>such as a Segger J-Link probe</router-link>,
        and then upload the alerts to cloud storage from the host computer.
        This method doesn't require any cloud connectivity in the device and thus works for a broader range of systems.
        This method can be suitable for using DevAlert during system testing.
        It is quite easy to adapt this solution to use a serial port or other direct interface instead of a J-Link.
      </p>
    </div>

    <h3 class="with-actions">
      Diagnostics
    </h3>
    <div class="help-section">
      <p>
        When creating an alert, you may include one or several diagnostic data payloads from the device.
        This is downloaded from your private storage using the <router-link :to='{ name: "Dispatcher"}'>DevAlert Dispatcher</router-link> tool when clicking on download links in the DevAlert web interface.
        The Dispatcher launches the appropriate desktop tool/viewer that is configured for the payload type.
        The configuration is handled by a <router-link :to='{ name: "DispatcherDetails"}'>wizard in the Dispatcher</router-link> tool.
      </p>
      <p>
        The diagnostic data may include system traces for <router-link :to='{ name: "Tracealyzer"}'>Percepio Tracealyzer</router-link>,
        crash dumps for GDB using the <router-link :to='{ name: "DfmCrashCatcher"}'>CrashCatcher integration</router-link>,
        existing application logs and any other data of relevance.
        If including a GDB crash dump, you can use any GDB-compatible debug tool to inspect the system state on your local desktop computer.
      </p>
      <p>
      </p>
    </div>

    <h3 class="with-actions">
      System Tracing
    </h3>
    <div class="help-section">
      <p>
        One type of diagnostics provided within DevAlert is system traces for Percepio Tracealyzer.
        This works by recording important software events into a circular buffer in RAM.
        Older events are continuously overwritten once the buffer becomes full.
        In case of alerts, the tracing is paused, and the trace buffer uploaded to provide a detailed timeline of the issue.
      </p>
      <p>
        <img src="@/assets/images/overview.png" alt="overview" style="width:100%;height:100%;object-fit:cover;" />
      </p>
      <p>
        The buffer size can be adjusted depending on the available memory. A few hundred bytes can be sufficient, although more is better.
      </p>
      <p>
        Note that the tracing must be stopped before storing or uploading the alert, as seen in <router-link :to='{ name: "DfmExamplesApplictionState"}'>this example</router-link>,
        to ensure that the trace buffer isn't modified by preempting threads during the process.
      </p>
      <p>
        You may add additional “user events” to get more information about the system execution.
        Learn more about this in the Tracealyzer user manual.
        Tracealyzer is included with DevAlert but is also a stand-alone product for inhouse debugging and analysis during the development phase.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showLocal: true
  }),
  mounted () {
    this.showLocal = this.$store.state.auth.filterTags.includes('Local')
  }
}
</script>
