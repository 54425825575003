<template>
  <div>
    <h2>Upload Trusted Certificate</h2>

    <p>
    Upload certificate used for authenticating Device Backend to
    DevAlert Service.
    </p>
    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
    <div v-if="certUploadDone" class="alert alert-success" role="alert">
        Successfully uploaded certificate
    </div>

    <div class="cert-container">
      <input type="file" id="file-selector" ref="file" style="display: none">
      <button v-if="!isLoading" type="button" @click="$refs.file.click()" class="btn btn-primary">
        Load Certificate
      </button>
    </div>
    <spinner v-if="isLoading"></spinner>
    <br>
    <h4>Alert Endpoint</h4>
    <spinner v-if="certDataLoading"></spinner>
    <div v-if="readStatusError" class="alert alert-danger" role="alert">
      {{ readStatusErrorMessage }}
    </div>
    <div v-if="!certDataLoading && !readStatusError">
      <table class="table table-striped table-sm" v-if="certExist">
        <tbody>
          <tr v-bind:class='{ unavailable: !isAvailable() }'>
            <td><b>
              Status</b>
            </td>
            <td>
              {{ status }}
            </td>
          </tr>
          <tr v-bind:class='{ "expired": hasExpired(expireDate), "expire-warning": soonExpired(expireDate) }'>
            <td><b>
              Certificate Expire Date</b>
            </td>
            <td>
              {{ expireDateToString() }}
            </td>
          </tr>
          <tr>
            <td><b>
              Certificate Effective Date</b>
            </td>
            <td>
              {{ $global.toLocaleDate(effectiveDate) }}
            </td>
          </tr>
          <tr>
            <td><b>
              Certificate Issuer Data</b>
            </td>
            <td>
              {{ issuer }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!certExist">
        No certificate uploaded
      </p>
      <div v-if="certExist && hasExpired(expireDate)" class="alert alert-danger" role="alert">
        Important! The certificate has expired and the device backend will
        not be able to send any alerts to the DevAlert Service. Update the
        certificate.
      </div>
      <div v-if="certExist && soonExpired(expireDate)" class="alert alert-warning" role="alert">
        Important! The certificate will soon expire and the device backend will
        not be able to send any alerts to the DevAlert Service. Update the
        certificate before the expire date to avoid missing any alerts
      </div>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'
import { Poller } from '../Utils/Global.js'
import router from '../../router/index'

export default {
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    if (this.$store.state.auth.role !== 'Owner') {
      router.push('/')
      return
    }

    this.poller = new Poller(2000, 8)
    this.setupClickEvent()
    this.autorefresh()
  },
  data: () => ({
    body: [''],
    isLoading: false,
    hasErrors: false,
    errorMessage: '',
    certUploadDone: false,
    versionId: 'init',
    poller: '',
    refreshPoller: null,
    expireDate: '',
    effectiveDate: '',
    issuer: '',
    status: '',
    certExist: false,
    certDataLoading: true,
    readStatusError: false,
    readStatusErrorMessage: ''
  }),
  methods: {
    uploadFile: function (file) {
      this.isLoading = true
      const reader = new FileReader()
      reader.onload = ((f) => {
        return (e) => {
          const content = e.target.result
          window.axios.post(this.$store.state.auth.serviceEndpoint + '/cert', content)
            .then(response => {
              this.versionId = response.data.versionId
              this.poller.get(this.$store.state.auth.serviceEndpoint + '/cert', (response) => {
                const d = response.data
                if (d.versionId === this.versionId) {
                  if (d.truststoreVersion === this.versionId) {
                    if (!d.apiMapping === false) {
                      return true // Stop polling on true
                    }
                  }
                }
                return false
              })
                .then(response => {
                  this.isLoading = false
                  this.certUploadDone = true
                  this.autorefresh()
                })
                .catch(error => {
                  window.console.error('Poll failed: ' + error.message)
                  this.isLoading = false
                  this.hasErrors = true
                  this.errorMessage = 'Certificate upload verification failed: ' + error.message
                })
            }).catch(error => {
              window.console.error('Upload error: ' + error.message)
              this.isLoading = false
              this.hasErrors = true
              this.errorMessage = 'Certificate upload failed: ' + error.message
            })
        }
      })(file)
      reader.readAsText(file)
    },
    setupClickEvent: function () {
      const fileSelector = document.getElementById('file-selector')
      fileSelector.addEventListener('change', (event) => {
        this.hasErrors = false
        this.certUploadDone = false
        const fileList = event.target.files
        if (!fileList[0] === false) {
          this.uploadFile(fileList[0])
        }
      })
    },
    autorefresh: function () {
      this.readStatusError = false
      this.refreshPoller = new Poller(5000)
      this.refreshPoller.get(this.$store.state.auth.serviceEndpoint + '/cert', (response) => {
        const d = response.data
        this.expireDate = d.data.expire
        this.effectiveDate = d.data.date
        this.issuer = d.data.issuer
        this.status = d.status
        this.certDataLoading = false
        if (this.expireDate != null) {
          this.certExist = true
        } else {
          this.certExist = false
        }
        return this.isAvailable() || !this.certExist
      })
        .catch(error => {
          window.console.error(error.message)
          this.certDataLoading = false
          this.readStatusError = true
          this.readStatusErrorMessage = 'Failed to read status: ' + error.message
        })
    },
    hasExpired: function (expireDate) {
      const e = new Date(expireDate)
      return new Date() > e
    },
    soonExpired: function (expireDate) {
      if (this.hasExpired(expireDate)) {
        return false
      }
      const e = new Date(expireDate)
      e.setDate(e.getDate() - 90)
      return new Date() > e
    },
    isAvailable: function () {
      return this.status === 'AVAILABLE'
    },
    expireDateToString: function () {
      let output = this.$global.toLocaleDate(this.expireDate)
      if (this.hasExpired(this.expireDate)) {
        output += ' Certificate has expired'
      }
      return output
    }
  },
  components: {
    spinner
  },
  beforeRouteLeave (to, from, next) {
    this.refreshPoller.stop()
    next()
  }
}
</script>
<style scoped>
.expired {
  background: indianred!important;
  color: white;
}
.expire-warning {
  background: orange!important;
  color: white;
}
.unavailable {
  background: indianred!important;
  color: white;
}
</style>
