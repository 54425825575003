<template>
  <div>
    <h2 class="list-page width-actions">
      Notifications
      <!--router-link class='btn btn-primary float-right' :to='{ name: "DevicesNew"}'>Add new</router-link-->
    </h2>

    <spinner v-if="isLoading"></spinner>

    <div class="table-responsive" v-if="!isLoading">
      <table class="table table-striped table-sm">
          <thead>
              <tr>
                  <th class="sort-by" @click="sortBy('name', $event)">
                    Topic
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th class="sort-by" @click="sortBy('state', $event)">
                    State
                    <span class="oi oi-caret-bottom"></span>
                  </th>
                  <th>Action</th>
              </tr>
          </thead>
          <tbody>
            <tr
              v-for="(dataRow, index) in collection"
              :key="index"
            >
              <td>{{ dataRow.name }}</td>
              <td>{{ dataRow.state }}</td>
              <td>
                <!--
                  <div v-if="dataRow.state === 'Active'" key="dataAction">Deactivate</div>
                  <div v-else-if="dataRow.state === 'Pending'">Pending</div>
                  <div v-else>Activate</div>
                  -->
                <router-link class='btn btn-secondary' :to='{ name: "NotificationsEdit", params: { id: dataRow.name }}'>Edit</router-link>
              </td>
            </tr>
          </tbody>
      </table>
      <pagination
        :total="paginate.total"
        :page-size="paginate.pageSize"
        :currentPage="paginate.currentPage"
        :callback="getTableData"
        nav-class="padding-10"
        ul-class="bg-color-red"
        li-class="txt-color-blue">
      </pagination>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ...
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import pagination from '../Utils/Paginate'
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {},
    searchFields: {
      sort: {
        sortName: 'name',
        sortOrder: 'DESC'
      }
    },
    paginate: {
      total: 1000,
      pageSize: 50,
      currentPage: 1
    },
    isLoading: true
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.getTableData(1)
    }
  },
  methods: {
    getTableData: function (page) {
      if (typeof page === 'undefined') {
        page = 1
      }

      this.isLoading = true

      const queryUri =
        '?page=' + page +
        '&sortName=' +
        this.searchFields.sort.sortName +
        '&sortOrder=' +
        this.searchFields.sort.sortOrder

      window.axios.get(this.$store.state.auth.serviceEndpoint + '/notifications' + queryUri)
        .then(response => {
          this.collection = response.data.topics
          this.paginate = response.data.pagination
          this.isLoading = false
        })
    },
    sortBy: function (field, event) {
      this.loading = true
      // Reset all to bottom
      if (field !== this.searchFields.sort.sortName) {
        $('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
        this.searchFields.sort.sortOrder = 'DESC'
      }
      this.searchFields.sort.sortName = field
      if (this.searchFields.sort.sortOrder === 'ASC') {
        this.searchFields.sort.sortOrder = 'DESC'
        $(event.currentTarget)
          .find('.oi.oi-caret-top')
          .removeClass('oi-caret-top')
          .addClass('oi-caret-bottom')
      } else {
        this.searchFields.sort.sortOrder = 'ASC'
        $(event.currentTarget)
          .find('.oi.oi-caret-bottom')
          .removeClass('.oi.oi-caret-bottom')
          .addClass('oi-caret-top')
      }
      return this.getTableData(this.currentPage)
    }
  },
  components: {
    spinner,
    pagination
  }
}
</script>
