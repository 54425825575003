<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Console Help
    </h2>
    <div class="help-section">
      The DevAlert Console gives you an overview of current issues, and also allows you to configure the DevAlert settings.
    </div>
    <router-link :to='{ name: "Dashboard"}'><h4 class="with-actions">Dashboard</h4></router-link>
    <div class="help-section">
      The Dashboard shows daily statistics for the last week, and a summary of the latest 5 issues.
      You can click the View button to see details of the Symptoms.
    </div>
    <h4 class="with-actions" v-if="!isAdmin">User Management</h4>
    <router-link :to='{ name: "Admin"}' v-if="isAdmin"><h4 class="with-actions">User Management</h4></router-link>
    <div class="help-section">
      There are three different Roles for a DevAlert user: Owner, Contributor, and Viewer.
      <ul>
        <li>Owner: Can manage Users, and can change DevAlert Configuration</li>
        <li>Contributor: Can change DevAlert Configuration, but not manage Users</li>
        <li>Viewer: Can see information in DevAlert Console, but not change anything</li>
      </ul>
      When setting up DevAlert for you, Percepio will create one user with Owner role who will then manage other users.
    </div>

    <h4 class="with-actions">DevAlert Configuration</h4>
    <router-link :to='{ name: "AlertTypes"}'><h5 class="with-actions">Alert Types</h5></router-link>
    <div class="help-section">
      Alert Types is a way to describe one partiular problem in the code. It contains a Type (numerical value generated by the database),
      a Definition, and a Description.
      <p>
        The Definition will be used in the header file. The first character must be a capital letter (A-Z), followed by capital letters (A-Z), numbers (0-9), or the underscore '_' character.
      </p>
      <p>
        The Description is the part that will be shown in the DevAlert Dashboard (both here in the Console and in Tracealyzer&#174;).
      </p>
      <h6 class="with-actions">Alert Type examples</h6>
      <p>
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>Type</th>
              <th>Definition</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>DEV_ALERT_TYPE_ASSERT_FAILED</td>
              <td>Assert Failed</td>
            </tr>
            <tr>
              <td>2</td>
              <td>DEV_ALERT_TYPE_MALLOC_FAILED</td>
              <td>Malloc Failed</td>
            </tr>
            <tr>
              <td>3</td>
              <td>DEV_ALERT_TYPE_HARDFAULT</td>
              <td>Hard Fault</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <router-link :to='{ name: "Symptoms"}'><h5 class="with-actions">Symptoms</h5></router-link>
    <div class="help-section">
      <p>
        Symptoms add additional data to an Alert. Similar to Alert Types they have a Key (numerical value generated by the database),
        a Definition, and a Description. Symptoms also have a Data Type, to help displaying the value correctly, and a Significant flag.
      </p>
      <p>
        The Definition will be used in the header file. The first character must be a capital letter (A-Z), followed by capital letters (A-Z), numbers (0-9), or the underscore '_' character.
      </p>
      <p>
        The Description is the part that will be shown in the DevAlert Dashboard (both here in the Console and in Tracealyzer).
      </p>
      <p>
        The Data Type allows different formatting of the Symptom value, either as int (showing the value as is), or hex (showing the hexadecimal value).
      </p>
      <h6 class="with-actions">Symptom examples</h6>
      <p>
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>Key</th>
              <th>Definition</th>
              <th>Description</th>
              <th>Data type</th>
              <th>Significant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>DEV_ALERT_SYMPTOM_LINE</td>
              <td>Line</td>
              <td>dec</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>5</td>
              <td>DEV_ALERT_SYMPTOM_PC</td>
              <td>PC</td>
              <td>hex</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>6</td>
              <td>DEV_ALERT_SYMPTOM_STACKPTR</td>
              <td>Stack Pointer</td>
              <td>hex</td>
              <td>No</td>
            </tr>
          </tbody>
        </table>
      </p>
    </div>
    <h5 class="with-actions"><router-link :to='{ name: "Devices"}'>Devices</router-link> and <router-link :to='{ name: "Products"}'>Products</router-link></h5>
    <div class="help-section">
      <p>
        A Device is a representation of actual physical devices, or targets. Devices can be added manually, or be automatically created when new alerts come in.
        Because of this, the Device does not use Published state, and their Identifier and Description can be modified at any time. Note that the Identifier is
        matched to the data coming in the Alert from the physical device, and should probably not be changed unless the physical device is updated.
      </p>
      <p>
        Products are a way of grouping different Devices that belong to the same product line together, allowing better filtering of issues.
        Like the other entries, it has an Identifier and a Description, and its State is handled using Publish and Retire.
        At any time there can only be one Default product in Published state. If you set a different Product to Default, the current one will be unset.
        Setting a Product to Retired will also unset Default.
      </p>
      <p>
        When a new Device is encountered by the DevAlert Classification Engine, a new Device entry is created and linked to the current Default product.
      </p>
    </div>

    <h5 class="with-actions">Entry States</h5>
    <div class="help-section">
      <p>
        It is not possible to <i>delete</i> any configuration, like Alert Types or Symptoms. It is also not possible to reuse an old number; adding a new entry will
        always use the next value. This is intentional, to ensure old alerts are not showing invalid information.
        When a new entry is added (Alert Type, Symptom, Product, or Device) it will allow changes to their descriptive text. This allows fixing of spelling errors,
        but it is not recommended to ever change the <i>meaning</i> of any Alert Type or Symptom that has been used.
      </p>
      <p>
        When the entry is ready, it should be Published. In this state, the entity can no longer be modified.
        If you have an entity that is no longer in use, it should be Retired. From this state it is possible to again Publish if the entry will be used again.
      </p>
    </div>

    <h4 class="with-actions" v-if="!isDeveloper">Code Export</h4>
    <router-link :to='{ name: "Headers"}' v-if="isDeveloper"><h4 class="with-actions">Code Export</h4></router-link>
    <div class="help-section">
      <p>
        To ensure that the DevAlert target code has a configuration that matches the DevAlert cloud, it is recommended to generate new header
        (i.e. new C header file) whenever an Alert Type or Symptom is changed.
        There are options to include or exclude entries that are not yet published, or entries that has been retired.
        When the header file is generated, a preview will show up in the DevAlert Console, and it will then be possible to Download the header file.
        It is highly recommended to always use the Header generation method, and never change dfmCodes.h manually,
        to ensure that the DevAlert target code is in sync with the DevAlert cloud.
      </p>
      <p>
        If the dfmCodes.h file have different names for an Alert Type, an Alert from a target will still be generated and handled, but the
        <router-link :to='{ name: "Dashboard"}'>Dashboard</router-link> will not show the expected name of the Alert.
      </p>
      <p>
        <b>Note!</b> Only users with an Owner or Contributor role can generate new header files.
      </p>
    </div>
    <router-link :to='{ name: "Notifications"}'><h4 class="with-actions">Notifications</h4></router-link>
    <div class="help-section">
      <p>
        Notifications can be sent out by DevAlert when certain actions happens.
        DevAlert currently sends out Issue Notifications, when a new Issue is detected by DevAlert Classification Engine,
        and Periodic Reports, which are sent out daily.
      </p>
      <p>
        For new users you are not subscribed to any Notifications. Use the <router-link :to='{ name: "Notifications"}'>Notifications</router-link>
        section (link on the top menu) to request subscriptions to any existing Notification, which will send an activation email.
        Any Notification you already are subscribed to, you can  remove the subscription from that section.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Getting Started with DevAlert</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
