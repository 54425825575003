import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/sass/app.scss'
import 'vue-material-design-icons/styles.css'
import * as global from './components/Utils/Global.js'
import veProgress from 'vue-ellipse-progress'
import VueMatomo from 'vue-matomo'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { load } from 'webfontloader'

window._ = require('lodash')
window.$ = window.jQuery = require('jquery')
window.Popper = require('popper.js').default
require('bootstrap')

/**
 * Ajax.
 */
window.axios = require('axios')

const devalertVuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1867C0',
          secondary: '#5CBBF6'
        }
      }
    }
  }
})
load({
  google: {
    families: ['Roboto:100,300,400,500,700,900&display=swap']
  }
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(devalertVuetify)
  .use(veProgress)
  .use(VueReCaptcha, {
    siteKey: '6Ld_6NMgAAAAAH9iqt4U3ecsJhETD0OBAUo-BZVG'
  })
  .use(VueMatomo, {
    host: 'https://percepio.matomo.cloud',
    siteId: 4,
    router: router
  })

app.config.globalProperties.$global = global
router.isReady().then(() => {
  app.mount('#app')
})
