<template>
  <div>
    <form class="form-signin" @submit.prevent="submitForm">
        <div class="text-center mb-2">
          <router-link to="/auth/login">
            <img class="img-signin" src="@/assets/images/DevAlert_logo.png" alt="logotype"/>
          </router-link>
        </div>

        <div v-if="isSubmitted">
          <p>
            Thanks for signing up! Check your email for credentials to <router-link to="/auth/login">log in</router-link>.
          </p>
        </div>

        <div v-if="hasErrors" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
        <spinner v-if="isLoading"></spinner>

        <div v-if="!isSubmitted && !isLoading" class="mb-5">
        <h4 class="text-center mb-3">Sign Up and try DevAlert for free</h4>

        <div class="form-group">
            <label for="inputFirstLastname">First Name</label>
            <input
                type="text"
                id="inputFirstLastname"
                class="form-control"
                placeholder="First Name"
                required
                autofocus
                v-model="form.firstName"
            />
        </div>

        <div class="form-group">
            <label for="inputFirstLastname">Last Name</label>
            <input
                type="text"
                id="inputFirstLastname"
                class="form-control"
                placeholder="Last Name"
                required
                autofocus
                v-model="form.lastName"
            />
        </div>

        <div class="form-group">
            <label for="inputEmail">Email</label>
            <input
                type="email"
                id="inputEmail"
                class="form-control"
                placeholder="Email Address"
                required
                autofocus
                v-model="form.email"
            />
        </div>

        <div class="form-group">
            <label for="inputCompany">Company</label>
            <input
                type="text"
                id="inputCompany"
                class="form-control"
                placeholder="Company Name"
                required
                autofocus
                v-model="form.company"
            />
        </div>

        <div class="form-group">
            <label for="inputReferer">How did you learn about DevAlert?</label>
            <input
                type="text"
                id="inputReferer"
                class="form-control"
                placeholder="How did you learn about DevAlert?"
                autofocus
                v-model="form.referer"
            />
        </div>

        <div class="form-check mt-3 mb-2">
          <input type="checkbox" class="form-check-input" id="inputConfirm" aria-describedby="emailHelp" v-model="confirmedCreate">
          <label class="form-check-label" for="inputConfirm">I accept the <router-link :to='{ name: "Privacy"}'>Privacy Policy</router-link> in order to create this account</label>
        </div>
        <p style="font-size:0.75rem">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>

        <button class="btn btn-lg btn-primary btn-block" type="submit" :disabled="!confirmedCreate">Register</button>
        <p class="text-white text-right">Already have an account?  <router-link to="/auth/login">Log in</router-link></p>
        </div>

        <p class="mt-5 mb-3 text-center text-white">Copyright&nbsp;©&nbsp;2017–{{ new Date().getFullYear() }}&nbsp;Percepio&nbsp;AB. All&nbsp;Rights&nbsp;Reserved.</p>
    </form>
  </div>
</template>

<script>
import { useReCaptcha } from 'vue-recaptcha-v3'
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    isLoading: false,
    isSubmitted: false,
    hasErrors: false,
    errorMessage: '',
    confirmedCreate: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      token: '',
      referer: ''
    },
    executeRecaptcha: null,
    recaptchaLoaded: null
  }),
  mounted () {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    this.executeRecaptcha = executeRecaptcha
    this.recaptchaLoaded = recaptchaLoaded
  },
  methods: {
    /**
     * Validate and submit form.
     */
    submitForm: function () {
      this.hasErrors = false
      this.isLoading = true

      this.recaptchaLoaded().then(() => {
        this.executeRecaptcha('login').then((token) => {
          this.form.token = token
          window.axios.post(this.$store.state.adminEndpoint + '/users/register', this.form).then(response => {
            this.isSubmitted = true
            this.isLoading = false
          }).catch(error => {
            window.console.error(error.message)
            this.hasErrors = true
            this.isLoading = false
            this.errorMessage = 'Failed to register user'
            if (error.response) {
              this.errorMessage += ': ' + error.response.data.title
            }
          })
        })
      })
    }
  },
  components: {
    spinner
  }
}
</script>

<style>
.grecaptcha-badge {
    visibility: hidden;
}
</style>
