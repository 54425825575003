<template>
  <div>
    <h2>Dashboard</h2>

    <div>
      <chart ref="chartRef"/>
    </div>

    <div class="mt-4 mb-2">
      <usage/>
    </div>

    <issues ref="issuesRef"/>
  </div>
</template>

<script>
import chart from './Chart.vue'
import usage from './Usage.vue'
import issues from './Issues.vue'

export default {
  components: {
    chart,
    usage,
    issues
  },

  mounted () {
    if (this.$store.state.auth.serviceEndpoint.length < 2) {
      this.$router.push({ name: 'Setup' })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.issuesRef.leave()
    this.$refs.chartRef.leave()
    next()
  }
}
</script>

<style scoped>
  .chart { grid-area: center; }
  .usage { grid-area: right; }
  .issues { grid-area: bottom; }
</style>
