<template>
  <div class="text-right">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="blue"
          v-bind="props"
          icon="mdi-wrench"
          size="x-small"
        >
        </v-btn>
      </template>
      <v-card min-width="300">
        <v-list>
          <v-list-item
            title="Column Settings"
          >
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item v-for="(preference, index) in columnPreferences"
          :key="index"
          :value="preference.value" hide-details>
            <v-switch @change="saveColumns"
              v-model="preference.value"
              color="blue"
              :label="preference.title"
              hide-details
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { saveColumnPreferences } from '../Utils/Global.js'
import { VBtn, VMenu, VCard, VList, VListItem, VSwitch, VDivider } from 'vuetify/components'

export default {
  data: () => ({
    menu: false
  }),
  methods: {
    saveColumns: function () {
      saveColumnPreferences(this.columnPreferences, this.columnPreferencesCacheKey)
    }
  },
  props: {
    columnPreferences: Object,
    columnPreferencesCacheKey: String
  },
  components: {
    VBtn,
    VMenu,
    VCard,
    VList,
    VListItem,
    VSwitch,
    VDivider
  }
}
</script>
