<template>
  <v-app>
    <Topbar v-if="isAuthenticated"></Topbar>
    <Navigation v-if="isAuthenticated"></Navigation>

    <v-main v-if="isAuthenticated">
      <v-container v-if="!isUserLoading" fluid>
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>

    <v-main id="login" class="blue-bg" v-if="!isAuthenticated">
      <v-container v-if="!isUserLoading" fluid>
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>

    <Expire v-if="isAuthenticated"/>
  </v-app>
</template>

<script>
import { VApp, VMain, VContainer } from 'vuetify/components'

import Navigation from './components/Menu/Navigation.vue'
import Topbar from './components/Menu/TopBar.vue'
import Expire from './components/Demo/Expire.vue'

export default {
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    },
    isUserLoading: function () {
      return this.$store.state.auth.isLoading
    }
  },
  watch: {
    isUserLoading: function () {
      if (this.$store.state.auth.authenticated) {
        window.axios.defaults.headers.common = {
          Authorization: 'Bearer ' + this.$store.state.auth.tokens.accessToken,
          'X-Cognito-IdToken': this.$store.state.auth.tokens.idToken
        }

        this.$store.dispatch('loadFeatures', this.$store.state.auth.serviceEndpoint)
      } else {
        this.$store.dispatch('clearFeatures')
      }
    }
  },
  components: {
    VApp,
    VMain,
    VContainer,
    Navigation,
    Topbar,
    Expire
  }
}
</script>
