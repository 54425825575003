import VueCookies from 'vue-cookies'

export function getColumnPreferences (columnPreferencesDefault, columnPreferencesCookieKey) {
  let columnPrefences = VueCookies.get(columnPreferencesCookieKey)

  if (columnPrefences === null) {
    VueCookies.set(columnPreferencesCookieKey, columnPreferencesDefault, '30d')
    columnPrefences = columnPreferencesDefault
  }

  return columnPrefences
}

export function saveColumnPreferences (columnPreferences, columnPreferencesCookieKey) {
  VueCookies.set(columnPreferencesCookieKey, columnPreferences, '30d')
}

export function queryString (data) {
  if (typeof data === 'string') {
    return data
  }

  const query = []
  for (const key in data) {
    if (typeof data[key] === 'object') {
      for (const nestedKey in data[key]) {
        query.push(
          encodeURIComponent(key) +
            '[' +
            nestedKey +
            ']=' +
            encodeURIComponent(data[key][nestedKey])
        )
      }
    } else if (Object.prototype.hasOwnProperty.call(data, key)) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
  }
  return query.join('&')
}

export function toLocalTime (dateStr) {
  return new Date(dateStr).toLocaleString()
}

export function toLocaleDate (dateStr) {
  return new Date(dateStr).toLocaleDateString()
}

export function disableOpenButton (data) {
  const payloads = data.payloads
  if (payloads == null) {
    // No payload - response from an old stack
    return false
  }
  if (payloads.find(p => p.key === 'da_trace' || p.key === 'trace' || p.filename.startsWith('dfm_trace.'))) {
    // Payload contains a trace
    return false
  }
  // Can be an empty alert or an old alert
  if (data.alertKey.includes('da_header')) {
    // New alert format and does not contain a trace
    return true
  }
  // Old alert format
  return false
}

export function getTraceLink (data) {
  // Strip filename from the path
  const alertKey = stripFilename(data.alertKey)
  const prefix = 'percepio://devalert/issue/' + alertKey
  if (data.payloads == null) {
    return prefix + '/trace'
  }
  const trace = data.payloads.find(p => p.key === 'da_trace' || p.key === 'trace' || p.filename.startsWith('dfm_trace.'))
  if (trace) {
    return prefix + '/' + trace.key
  }
  // Has no trace (open will be disabled) or old alert format
  return prefix + '/trace'
}

export function stripFilename (alertKey) {
  if (alertKey.includes('_header')) {
    // AlertKey contains filename
    return alertKey.substring(0, alertKey.lastIndexOf('/'))
  }
  return alertKey
}

export function showLoader (element, dismissTimeMs = 7000) {
  element.isLoading = true
  setTimeout(() => {
    element.isLoading = false
  }, dismissTimeMs)
}

/*
 * Poller class
 * property preGet is a callback that is called
 * before every get request. Can be used to update
 * query parameters (the property 'qp') before
 * every poll.
 */
export class Poller {
  // count 0 means poll forever
  constructor (interval, count = 0) {
    this.pollInterval = interval
    this.maxPoll = count
    this.runner = null
    this.preGet = null
    this.qp = ''
  }

  stop () {
    clearInterval(this.runner)
  }

  get (url, predicate) {
    const p = this
    let pollCount = 0
    const promise = new Promise(function (resolve, reject) {
      const callback = () => {
        p.pollAPI(url, predicate)
          .then(response => {
            if (response.state === 'AGAIN') {
              if (!p.maxPoll || pollCount++ < p.maxPoll) {
                // do nothing here
              } else {
                clearInterval(p.runner)
                reject(new Error('Poll limit reached - giving up'))
              }
            }
            if (response.state === 'DONE') {
              clearInterval(p.runner)
              resolve(response.response)
            }
          })
          .catch(error => {
            reject(error)
            clearInterval(p.runner)
          })
      }
      callback()
      p.runner = setInterval(callback, p.pollInterval)
    })
    return promise
  }

  pollAPI (url, predicate) {
    const p = this
    const promise = new Promise(function (resolve, reject) {
      if (p.preGet) {
        p.preGet()
      }
      window.axios.get(url + p.qp)
        .then(response => {
          if (predicate(response)) {
            const result = { state: 'DONE', response: response }
            resolve(result)
          } else {
            // Continue poll
            const result = { state: 'AGAIN', response: response }
            resolve(result)
          }
        })
        .catch(error => {
          window.console.log('Poll error: ' + error.message)
          reject(error)
        })
    })
    return promise
  }
}
