<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      DevAlert Firmware Monitor (DFM) Library Help
    </h2>
    <div class="help-section">
      <p>
        The DevAlert Firmware Monitor (DFM) library is the Target side code that collects and transfers the Alert data from the Target
        to the DevAlert cloud service.
      </p>
      <p>
        Get the <router-link :to='{ name: "DfmFiles"}'>DFM Files</router-link>.
      </p>
    </div>

    <h4 class="with-actions">DFM library file structure</h4>
    <div class="help-section">
      <div class="help-paragraph">
      <p>
        One part of the DFM library contains core files that are not supposed to be changed and one part are ports supposed to be implemented
        by users. There are also config header files where config parameters are defined that should be tweaked by the user.
      </p>
       <div class="row">
         <div class="file-column">
           dfm.c/h
         </div>
         <div class="desc-column">
           Main Dfm file. Dfm initialization.
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmAlert.c/h
         </div>
         <div class="desc-column">
           Dfm public API for handling alerts
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmCloud.c/h
         </div>
         <div class="desc-column">
           Internal module calling cloud port API
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmCrashCatcher.c/h
         </div>
         <div class="desc-column">
            Optional:
            <router-link :to='{ name: "DfmCrashCatcher"}'>
            implementation of CrashCatcher
            </router-link>
            callback functions for use with DFM
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmEntry.c/h
         </div>
         <div class="desc-column">
          Internal Dfm API for managing internal entry struct
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmSession.c/h
         </div>
         <div class="desc-column">
          Public API for setting session data
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmStorage.c/h
         </div>
         <div class="desc-column">
          Internal module calling storage port API
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmDefines.h
         </div>
         <div class="desc-column">
          Defines for return codes, status codes etc
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmTypes.h
         </div>
         <div class="desc-column">
          Define various public DFM types
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmKernelPort.c/h
         </div>
         <div class="desc-column">
          Implementation of internal kernel port API, implement to fit your need
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmCloudPort.c/h
         </div>
         <div class="desc-column">
          Implementation of internal cloud port API, implement to fit your need
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmStoragePort.c/h
         </div>
         <div class="desc-column">
          Implementation of internal storage port API, implement to fit your need
         </div>
       </div>
       <div class="row">
         <div class="file-column">
           dfmCodes.h
         </div>
         <div class="desc-column">
           defines the Alert and Symptom codes. This file is generated from the
           <router-link :to='{ name: "Headers"}' v-if="isDeveloper">Code Export</router-link>
           <div v-if="!isDeveloper">Code Export</div>
           section.
         </div>
       </div>
      </div>
    </div>
    <h4 class="with-actions">Integration steps</h4>
    <div class="help-section">
      <p>
      <ol>
        <li>
          <div class="help-paragraph">
            Make sure recorder is configured either in snapshot mode or streaming mode with ringbuffer;
            follow user manual in Tracealyzer&#174; documentation.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            Add the DFM source code to your target project.
            Make sure to include the right dfmCloudPort.c/.h and dfmStoragePort.c/.h corresponding to your setup. or develop these using the existing examples.
            Note that some cloudports and storageports are found under /kernelports/&lt;RTOS&gt;.
            It is recommended to use the following file structure:
            <pre>
              <code>
[project root]
    └── DFM/
        ├── config
        │   ├── dfmConfig.h
        │   └── dfmCloudPortConfig.h    from the selected cloudport directory
        ├── dfm.c
        ├── dfmAlert.c
        ├── dfmCloud.c
        ├── dfmCrashCatcher.c           optional: only needed if using CrashCatcher
        ├── dfmEntry.c
        ├── dfmSession.c
        ├── dfmStorage.c
        ├── dfmUser.c                   user-defined functions needed by DFM
        ├── include
        │   ├── dfm.h
        │   ├── dfmAlert.h
        │   ├── dfmCloud.h
        │   ├── dfmCodes.h
        │   ├── dfmCrashCatcher.h       optional: only needed if using CrashCatcher
        │   ├── dfmDefines.h
        │   ├── dfmEntry.h
        │   ├── dfmSession.h
        │   ├── dfmStorage.h
        │   ├── dfmUser.h
        │   ├── dfmTypes.h
        │   ├── dfmKernelPort.h         from the selected kernelport directory
        │   ├── dfmCloudPort.h          from the selected cloudport directory
        │   └── dfmStoragePort.h        from the selected storageport directory
        ├── dfmKernelPort.c     from the selected kernelport directory
        ├── dfmCloudPort.c      from the selected cloudport directory
        └── dfmStoragePort.c    from the selected storageport diretory
              </code>
            </pre>
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            If needed, modify your build configuration to have <i>DFM/include</i> and <i>DFM/config</i> in your include path.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            Modify dfmConfig.h and dfmCloudPortConfig.h to fit your setup.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            The user-defined functions in <samp>dfmUSer.c</samp> called <samp>xDfmUserGetDeviceName</samp> and <samp>xDfmUserGetUniqueSessionID</samp> in <i>dfmUser.c</i> according to our
            <router-link :to='{ name: "AlertKeys"}'>Alert Key Uniqueness</router-link> guide.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            Generate a new <i>dfmCodes.h</i> file from the
            <router-link :to='{ name: "Headers"}' v-if="isDeveloper">Code Export</router-link>
            <div v-if="!isDeveloper">Code Export</div>
            section, or take the latest one you have in your own repository or similar.
          </div>
          <div class="help-paragraph">
            <b>Note!</b> You should never manually edit the <i>dfmCodes.h</i> file, because the numerical values are interpreted by
            DevAlert and Tracealyzer to show descriptive strings instead of pure numbers, and if the numbers are not synchronized
            the wrong text will be shown. Because of this, the DevAlert configuration should always be treated as the master,
            and <i>dfmCodes.h</i> should be generated.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            Add a call to <i>xDfmInitialize()</i> after a network connection has been established.
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            <p>
              Update your target code where you know that you have a problem if reached.
              This includes both problems detected by the target/RTOS, like fault handlers, and application problems,
              like the default label in a switch where the value is not valid.
            </p>
            <p>
              In each of these locations, start by creating an alert by calling <i>xDfmAlertBegin()</i> with alert type and any
              text description for the alert. The call creates an alert handle that should be used when making additional operations
              on this alert.
            </p>
            <p>
              Then add any symptoms needed with <i>xDfmAlertAddSymptom()</i> to provide
              additional numeric values.
            </p>
            <p>
              Add any payloads that should be attached to the alert, trace, file, crash dump etc,
              using <i>xDfmAlertAddPayload()</i>.
              When payloads are added, call <i>xDfmAlertEnd()</i>. The alert will now be uploaded.
            </p>
            <p>
              In case it is not possible to upload the alert at this point one can set the cloud strategy to Offline using
              <i>xDfmSessionSetCloudStrategy(DFM_CLOUD_STRATEGY_OFFLINE)</i>. The alert will then be stored using the storage
              port when calling <i>xDfmAlertEnd()</i>. At a later time, possibly after a reboot, when it is possible to upload alerts, call <i>xDfmAlertSendAll()</i>
              to upload all stored alerts.
            </p>
            <p>
              <b>Note!</b> If adding a trace payload to the alert you need to suspend the trace facility using <i>xTraceDisable()</i> to
              prevent trace data from being corrupted while creating the alert, and resume it after
              the send is done.
            </p>
          </div>
        </li>
        <li>
          <div class="help-paragraph">
            Have a look at the <router-link :to='{ name: "DfmExamples"}'>example section</router-link> for ideas on where to set up alerts,
            with example code for each of them.
          </div>
        </li>
      </ol>
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>
<style scoped>
.file-column {
  float: left;
  width: 40%;
  display: table;
  padding-left: 25px;
}
.desc-column {
  float: left;
  width: 60%;
  align-items: left;
}
.row {
  content: "";
  clear: both;
  display: flex;
  margin-left: 0px;
  margin-bottom: 5px;
}
</style>
