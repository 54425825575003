<template>
  <div class="col-md-7" style="margin-left:0">
    <h2 class="with-actions">
      Setup Percepio DevAlert Service
    </h2>
    <div class="help-section">
      <p>
        The Percepio DevAlert Service setup is done entirely by Percepio but requires a few details, like geographical region,
        to ensure the usage is not limited by high latency, and an estimated device count, to ensure the setup is
        scaled accordingly.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "GettingStarted"}'>Back to Getting Started</router-link>
      </div>
    </div>
  </div>
</template>
