<template>
  <div>
    <spinner v-if="isLoading"></spinner>
    <form class="form-signin" v-on:submit.prevent="verifyPassword()" v-if="!isLoading">

        <div class="text-center mb-2">
          <img class="img-signin" src="@/assets/images/DevAlert_logo.png" alt="logotype"/>
        </div>

        <div v-if="hasErrors" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div class="alert alert-info">
          New password must be at least 8 characters long. Contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character!
        </div>

        <div class="form-label-group mb-3">
          <input
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required
            v-model="password"
            @input="passwordUpdated"
          >
          <label for="inputPassword">New password required</label>
        </div>

        <div class="form-label-group">
          <input
            type="password"
            id="repeatPassword"
            class="form-control"
            placeholder="Repeat Password"
            required
            v-model="repeatPassword"
            @input="passwordUpdated"
          >
          <label for="repeatPassword">Repeat password</label>
        </div>

        <button class="btn btn-lg btn-primary btn-block" type="submit" :disabled="!canSubmit">Confirm</button>

        <p class="mt-5 mb-3 text-center text-white">Copyright&nbsp;©&nbsp;2017–{{ new Date().getFullYear() }}&nbsp;Percepio&nbsp;AB. All&nbsp;Rights&nbsp;Reserved.</p>
    </form>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    isLoading: false,
    password: '',
    repeatPassword: '',
    canSubmit: false,
    errcode: '',
    errorMessage: '',
    hasErrors: false
  }),
  computed: {
    errorCode: function () {
      return this.$store.state.auth.errcode
    }
  },
  methods: {
    verifyPassword: function () {
      this.isLoading = true

      this.$store.dispatch('handleNewPassword', this.password)
    },
    passwordUpdated: function () {
      this.canSubmit = (this.password.length > 0 && this.password === this.repeatPassword)
    }
  },
  watch: {
    errorCode () {
      if (this.errorCode !== '') {
        if (this.errorCode === '"InvalidPasswordException"') {
          // TODO: This is ugly, it would be better to propagate the error
          // message from the response rather than hard code the same message here
          this.errorMessage = 'Provided password cannot be used for security reasons'
        } else if (this.errorCode === '"NotAuthorizedException"') {
          this.errorMessage = 'Invalid session for the user, session is expired'
        } else if (this.errorCode === '"LimitExceededException"') {
          this.errorMessage = 'Attempt limit exceeded, please try after some time'
        } else {
          this.errorMessage = 'An error has occured!'
        }
        this.hasErrors = true
        this.isLoading = false
      } else {
        this.hasErrors = false
        this.isLoading = true
      }
    }
  },
  components: {
    spinner
  }
}
</script>
