<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div v-if="!isLoading">
      <router-link class='btn btn-secondary' :to='{ name: "Products"}'>Back</router-link>

      <h2 class="with-actions">
        Edit {{ originalDescription }}
      </h2>

      <spinner v-if="isLoading"></spinner>

      <form v-on:submit.prevent="submitForm">
        <div class="float-right status-bar">
          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-success" :disabled="collection.published && !collection.retired" v-on:click="updateStatus('published')">
              Publish
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button type="button" class="btn btn-block btn-info" :disabled="collection.retired" v-on:click="updateStatus('retired')">
              Retire
            </button>
          </div>

          <div class="float-left" v-if="collection.created">
            <button class="btn btn-secondary"  type="submit" :disabled="collection.published">
              Save Updates
            </button>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Identifier</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.identifier" pattern="[A-Z][A-Z0-9_]+" required :disabled="collection.published">
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Description</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" v-model="collection.description" :disabled="collection.published" required>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Is Default</label>
          <select class="form-control" v-model="collection.isDefault" :disabled="collection.published">
            <option :value="true">YES</option>
            <option :value="false">NO</option>
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">Created</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.created" disabled>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Updated</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.updated" disabled>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Published</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.published" disabled>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Retired</label>
          <input type="text" class="form-control" id="exampleFormControlInput1" :value="collection.retired" disabled>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {
      key: null,
      identifier: null,
      description: null,
      created: null,
      updated: null,
      published: null,
      retired: null,
      isDefault: null
    },
    originalDescription: '',
    isLoading: true
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    }
  },
  mounted () {
    if (!this.isAuthenticated) {
      return
    }
    this.getEditData()
  },
  methods: {
    getEditData: function () {
      window.axios.get(this.$store.state.auth.serviceEndpoint + '/products/' + this.$route.params.id)
        .then(response => {
          if (!response.data.productDescriptions.length) {
            this.$router.push('/devices')
          }

          this.collection = response.data.productDescriptions[0]
          this.originalDescription = this.collection.description
          this.isLoading = false
        })
    },
    submitForm: function () {
      this.submitData({
        identifier: this.collection.identifier,
        description: this.collection.description,
        isDefault: this.collection.isDefault
      })
    },
    updateStatus: function (field) {
      this.collection.state = field

      this.submitData({
        state: this.collection.state
      })
    },
    submitData: function (collection) {
      window.axios({
        method: 'PATCH',
        url: this.$store.state.auth.serviceEndpoint + '/products/' + this.$route.params.id,
        data: collection
      }).then(response => {
        this.collection = response.data.productDescriptions[0]
        this.originalDescription = this.collection.description
      })
    }
  },
  components: {
    spinner
  }
}
</script>
